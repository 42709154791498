import { getScholarshipDetails } from "API/webAPI";
import CircleProgress from "components/CircleProgress";
import Loader from "components/Core/Loader";
import Text from "components/Core/Text";
import { SimilarityList } from "components/Major/MajorDetails";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import HeaderTitle from "components/University/UniversityDetails/HeaderTitle";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import styled from "styled-components";
import { CustomMarkupParser } from "utils/CustomMarkupParser";
import { Routes, Size, Space } from "utils/constants";

const reducer = (_, action) => {
    switch(action.type) {
        case 'fetch-start':
            return { loading: true, error: null, scholarship: {} }
        case 'fetch-done':
            return { loading: false, error: null, scholarship: action.data }
        case 'fetch-failed':
            return { loading: false, error: action.error, scholarship: {} }
    }
}
const markupParser = new CustomMarkupParser()
const _extractListItems = (value: string) => {
    return markupParser.parse(value)
}
const ScholarshipDetails: React.FC<{id: number}> = ({ id }) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        error: null,
        scholarship: {}
      });
    const { scholarship, loading } = state;

    const onScholarshipIdChange= useCallback(async (id: number) => {
        try {
            dispatch({ type: 'fetch-start' })
            const results = await getScholarshipDetails(id)
            dispatch({ type: 'fetch-done', data: results })
        } catch(err) {
            dispatch({ type: 'fetch-failed', error: err })
        }
    }, [])

    useEffect(() => {
        let unmount = false
        if (unmount || !id) return
        onScholarshipIdChange(id)
        return () => {
            unmount = true
        }
    }, [id])
    
    const stepsList = useMemo(() =>  markupParser.parse(scholarship.steps) ?? scholarship.steps , [scholarship]);
    const requirementsList = useMemo(() => markupParser.parse(scholarship.requirements) ?? scholarship.requirements, [scholarship]);
    const aboutList = useMemo(() => markupParser.parse(scholarship.about) ?? scholarship.about, [scholarship]);
    const programsCovered = useMemo(() => markupParser.parse(scholarship.programsCovered) ?? scholarship.programsCovered, [scholarship]);
    const coverage = useMemo(() => scholarship.amount?.includes('-') ? parseInt(scholarship.amount.split('-')[1]) : parseInt(scholarship.amount), [scholarship.amount])
    if (loading || Object.keys(scholarship).length === 0) return <Loader/>
    return (
        <>
        <HeaderTitle text={scholarship.name} hideUnderLine/>
        {scholarship.amount &&
            <>
            <HeaderTitle text="Tuition Cover" style={{ marginTop: Space.large }}/>
            <FlexDirection.Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <CircleProgress customText={scholarship.amount} textSize={Size.medium} value={coverage} height='90px' width='100px' activeColor='#50C878'/>
            </FlexDirection.Row>
            </>}
        <Item title={'About'} data={aboutList}/>
        <Item title={'Requirements'} data={requirementsList}/>
        <Item title={'Programs'} data={programsCovered}/>
        <Item title={'Procedure'} data={stepsList}/>
        <HeaderTitle text="Offered At" style={{ marginTop: Space.large }}/>
        <div style={{ marginLeft: Space.medium }}>
        <SimilarityList items={[{ name: scholarship.uniName, acronym: scholarship.uniAcronym, id: scholarship.uniId }]} page={Routes.scholarshipDetails + `/${scholarship.name?.replaceAll(' ', '-')}`}/>
        </div>
        </>
    )
}

const Item = ({ data, title}) => {
    const renderList = useCallback((value: string[] | string) => {
        if (typeof(value) === 'string') {
            if (value.startsWith('https')) {
                return (
                    <Text>
                        Visit {' '} <a href={value} children={value} style={{ color: 'blue'}} target="_blank"/>
                    </Text>
                )
            } 
            return <Text children={value}/>
        }
        return value
    }, [])
    if (!data || data === '-') return
    return (
        <>
        <HeaderTitle text={title} style={{ marginTop: Space.large }}/>
        <UnorderedList>
            {renderList(data)}
        </UnorderedList>
        </>
    )
}

const UnorderedList = styled.div`
    margin-left: ${Space.medium};
    list-style-type: disc;
`
export default ScholarshipDetails;