import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useMemo } from 'react';
import { Colors, CustomBreakPoints, Routes, Size, Space } from 'utils/constants';
import { HeroSectionVariants, ListingItems } from 'utils/types';
import { University } from 'types/University';
import ScholarshipItem from 'components/Scholarship/ScholarshipItem';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import UniversityItem from 'components/University/UniversityItem';
import Pagination from 'components/Pagination';
import IllustratedView from 'components/IllustratedView';
import { generateSequence } from 'utils/helpers';
import { ScholarshipListItem } from 'types/Scholarship';
import Modal from 'components/Core/Modal';
import { ReactComponent as FilterIcon } from 'assets/svgs/icons/filter.svg';
import { ReactComponent as EmptyIllustration } from 'assets/svgs/illustrations/empty-box.svg';
import Text from 'components/Core/Text';
import { FilterContent } from 'components/Filter/FilterContent';
import SearchEngine from 'components/SearchEngine';
import { store } from 'redux/store';
import { FlexDirection } from 'components/StyledComponents/FlexDirection';
import { setDiff } from 'redux/slices/filterSlice';

interface Props {
    items: ScholarshipListItem[] | University[];
    heroContent: { title: string, description: string, variant: HeroSectionVariants, illustration: any, isReversed?: boolean };
    type: ListingItems;
    paginationInfo: { count: number, next?: number, prev?: number, params?: string, current: number, size: number };
    loading?: boolean;
    id?: number;
    extensions?: { setSelectedId?: any };
    refresh?: any;
};
const ItemsListing: React.FC<Props> = ({ items, heroContent, type, extensions, paginationInfo, loading, refresh }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const data = useMemo(() => loading ? generateSequence(paginationInfo.size) : items, [loading])
    return (
        <Wrapper id='items-listing'>
            {type === ListingItems.Scholarship &&
                <FilterButton isMobile={isMobile} htmlFor="filter-modal" id='button-container'>
                    <Text fontWeight={600} children={'Filter'} color='white' margin={{ right: Space.medium }}/>
                    <FilterIcon height={25} width={25} />
                </FilterButton>}
            <IllustratedView
                type={type}
                title={heroContent.title}
                subtitle={heroContent.description}
                isSecondary
                dynamicComponet='INPUT'
                button={{text: '', onPress: () => {}}}
                illustration={heroContent.illustration} />
            <div style={styles.contentWrapper(isMobile)}>
            {isMobile &&
                <div style={styles.filterContainer}>
                    <div style={{ width: '90%', position: 'relative'}}>
                        <SearchEngine type={type} overrideClasses='w-full'/>
                    </div>
                </div>}
                <List>
                    {!loading && data?.length === 0
                        && <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                            <EmptyIllustration height={"100%"} width={isMobile ? "8ß0%" : "30%"} style={{ marginRight: Space.largest }}/>
                            <FlexDirection.Column style={{ marginLeft: Space.largest, marginTop: Space.medium }}>
                                <Text fontWeight={600} fontSize={isMobile ? Size.large : Size.larger}>
                                    No {type === ListingItems.Scholarship ? "Scholarships " : "Universities "} Found :(
                                </Text>
                                {!isMobile &&
                                    <Text fontSize={Size.large_medium}>
                                        Maybe you can try to use other filter options ?
                                    </Text>}
                            </FlexDirection.Column>
                           </div>}
                    {data?.map((item, index) => 
                        type === ListingItems.Scholarship
                            ? <ScholarshipItem isLoading={loading} key={index} scholarship={item} onPress={extensions.setSelectedId}/>
                            : <UniversityItem key={index} university={item}  index={index} isLoading={loading}/>)}
                </List>  
            </div>
            <Pagination pageSize={paginationInfo.size} currentPage={paginationInfo.current ?? 0} totalPages={Math.ceil(paginationInfo.count / paginationInfo.size)} route={type === ListingItems.University ? Routes.university : Routes.scholarship} params={paginationInfo.params}/>
            <Modal id="filter-modal"  width={isMobile ? "100%" : "60%"} onClose={() => store.dispatch(setDiff(false))}>
                <FilterContent.ScholarshipFilters refresh={refresh}/>
            </Modal>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: ${Colors.white};
    width: 100%;
    margin: auto;
    margin-top: 3.5em;
    @media (max-width: ${CustomBreakPoints.phone}) {
        width: 100%;
    }
`
const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: ${Space.larger};
    flex: 1;
`
const FilterButton = styled.label<{ isMobile: boolean  }>`
    background-color: ${Colors.purple};
    border-radius: 12px;
    padding: ${Space.medium};
    padding-right: ${Space.large};
    padding-left: ${Space.large};
    color: white;
    position: fixed;
    right: ${props => props.isMobile ? '15px' : '50px'};
    bottom: ${props => props.isMobile ? '6%': '6%'};
    font-size: ${Size.large_medium};
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
`
type Style = {
    searchBar: React.CSSProperties;
    contentWrapper: (isMobile: boolean) => React.CSSProperties;
    filterContainer: React.CSSProperties;
    drawer: React.CSSProperties;
    desktopDrawer: React.CSSProperties;
    drawerHeader: React.CSSProperties;
    filterHeader: React.CSSProperties;
    list: React.CSSProperties;
}
const styles: Style = {
    searchBar: {
        width: '70%',
        marginLeft: Space.large
    },
    contentWrapper: (isMobile) => ({
        margin: Space.medium,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100%'
    }),
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: Size.thin_medium,
        fontWeight: 'bold',
        width: '100%',
        marginBottom: Space.large
    },
    filterHeader: {
        display: 'flex',
        flex: 0.4,
        height: '100%',
        flexDirection: 'column',
        padding: Space.larger,
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: Colors.offWhite,
        borderStyle: 'solid'
    },
    drawer: {
        height: '85%',
        padding: Space.large,
        paddingTop: '0px',
        boxSizing: 'border-box',
        overflowY: 'scroll',
        zIndex: 1000,
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
    },
    desktopDrawer: {
        backgroundColor: 'transparent',
        height: '85%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none'
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '9px',
        position: 'sticky',
        width: '100%',
        padding: Space.medium,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.white
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center' 
    }
}
export default ItemsListing;



    // useEffect(() => {
    //     const handleScroll = () => {
    //       const button = document.getElementById('button-container');
    //       const footer = document.getElementById('footer');
          
    //       if (button && footer) {
    //         const buttonBottom = button.getBoundingClientRect().bottom;
    //         const footerTop = footer.getBoundingClientRect().top;
    //         if (buttonBottom >= footerTop) {
    //           setVisible(false);
    //         } else {
    //           setVisible(true);
    //         }
    //       }
    //     };
    
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);