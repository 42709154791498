import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Colors, Space, CustomBreakPoints, Size, Routes } from "utils/constants";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ReactComponent as MenuIcon } from "assets/svgs/icons/burger-icon.svg";
import Drawer from "react-modern-drawer";
import 'components/css/common.css'
import Text from "./Core/Text";
import Link from "./Core/Link";
import { goTo } from "utils/helpers";

const TabsContent = [
  { text: "Home", route: Routes.home },
  { text: "Universities", route: Routes.university },
  { text: "Scholarships", route: Routes.scholarship },
  { text: "Contact Us", route: Routes.contactUs },
];

const NewNavBar = () => {
  const isMobile = useMediaQuery(`(max-width:${CustomBreakPoints.tablet})`);
  const [isDrawerOpen, setDrawerStatus] = useState(false);
  const toggleDrawer = useCallback(() => {
    setDrawerStatus((prev) => !prev);
  }, []);

  return (
    <>
      <NavBarContainer id='navbar'>
        <NavBarInnerContainer>
          <LogoContainer>
            <div onClick={() => goTo(Routes.home, [], true)}>
              <Text fontSize={isMobile ? Size.large : Size.xxLarge} fontWeight={700} color={Colors.primary}>
                Jusoor.io
              </Text>
            </div>
          
          </LogoContainer>
          <TabsContainer>
            <RowDiv>
              {TabsContent.slice(0, 3).map(tab => {
                return (
                  <NavBarOne key={tab.route}>
                      <NavTab
                        to={tab.route}
                        children={tab.text}
                        onClick={() => setDrawerStatus(false)}/>
                  </NavBarOne>
                );
              })}
            </RowDiv>
            <RowDiv>
              {TabsContent.slice(3, 5).map(tab => {
                  return (
                    <NavBarTwo key={tab.route}>
                      <NavTab
                        to={tab.route}
                        children={tab.text}
                        onClick={() => setDrawerStatus(false)}/>
                    </NavBarTwo>
                  );
              })}
            </RowDiv>
          </TabsContainer>

          {isMobile && (
            <>
              <Drawer
                open={isDrawerOpen}
                onClose={toggleDrawer}
                direction="right"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: Space.medium,
                  padding: Space.medium,
                  width: "50%",
                }}
              >
                <Text color={Colors.primary} fontSize={Size.xLarge} fontWeight={700} children={'Jusoor.io'} style={{alignSelf: 'center'}}/>
                {TabsContent.map((tab) => {
                  return (
                    <Tab
                      key={tab.route}
                      to={tab.route}
                      onClick={() => setDrawerStatus(false)}
                      children={tab.text}
                    />
                  );
                })}
              </Drawer>
             
              <MenuWrapper onClick={toggleDrawer}>
                <MenuIcon fill={Colors.primary} height="25px" width="30px"/>
              </MenuWrapper>
            </>
          )}
        </NavBarInnerContainer>
      </NavBarContainer>
    </>
  );
};

export default NewNavBar;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const NavBarContainer = styled.div`
  position: fixed;
  z-index: 2000;
  background-color: ${Colors.greyWhite};
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  display: flex;
  justify-content: center;
  width: 100%;
  backdrop-filter: "blur(10px)";
`;
const NavBarInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Space.large};
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    width: 80%;
    min-height: 45px;
  }
`;
const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 1;
`;
const NavBarOne = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${Space.largest};
  @media (max-width: ${CustomBreakPoints.desktop}) {
    margin-left: ${Space.large};
  }
`;
const NavBarTwo = styled(NavBarOne)`
  /* width: 100%; */
  max-width: 150px;
  display: flex;
  /* border: 2px solid #ad58b2; */
  justify-content: center;
  align-items: center;
  /* gap: ${Space.largest}; */
  @media (max-width: ${CustomBreakPoints.desktop}) {
    margin-left: ${Space.large};
  }
`;
const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;
const MenuWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 10;
  justify-content: flex-end;
`;
const NavTab = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: ${Colors.black};
  font-size: ${Size.large_medium};
  padding-left: ${Space.large};
  padding-right: ${Space.large};
  font-size: ${Size.large_medium};
  @media (max-width: ${CustomBreakPoints.desktop}) {
    font-size: ${Size.thin_medium};
  }
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
  &.active {
    color: ${Colors.purple};
    text-decoration-thickness: 0.2em;
    text-decoration-color: ${Colors.purple}
  }
  &:hover {
    cursor: pointer;
    color: ${Colors.purple};
    text-decoration: underline;
    text-decoration-thickness: 0.2em;
    text-decoration-color: ${Colors.purple};
  }
`;
const Tab = styled(Link)`
  padding-left: 10px;
  padding-right: 10px;
  color: ${Colors.primary};
  font-weight: bold;
  font-size: ${Size.large_medium};
  text-decoration: none;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    margin-bottom: ${Space.large};
    flex: 1;
  }
  &:hover {
    text-decoration: underline;
  }
  &.active {
    background-color: ${Colors.purple};
    color: ${Colors.white};
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 6px
  }
`;
