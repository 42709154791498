import { captureException } from "@sentry/react"
import axios from "axios"
import { Routes } from "utils/constants"
import { goTo } from "utils/helpers"

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": 'application/json'
    }
})
const get = (endpoint: string, params?: any) => {
    return api.get(endpoint, { params })
}
const post = (endpoint: string, body?: any) => {
    return api.post(endpoint, body)
}

export const handleApiErrors = (error: any) => {
    console.log('Error API', error)
    captureException(error)
}
export const JusoorAPI = { get, post }