import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Space, Size, Colors, CustomBreakPoints, defaultImage, Routes } from 'utils/constants';
import Text from 'components/Core/Text';
import { ReactComponent as CalendarIcon } from 'assets/svgs/icons/calendar.svg'
import CircleProgress from 'components/CircleProgress';
import { ScholarshipListItem } from 'types/Scholarship';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { skeletonState } from 'components/StyledComponents/SkeletonAnimation';
import { logNavigateEvent } from 'tracking/google';
export interface ScholarshipItemProps {
    scholarship?: ScholarshipListItem;
    onPress?: any;
    isLoading?: boolean;
}
const ScholarshipItem: React.FC<ScholarshipItemProps> = (props) => {
    const { name, university, deadline, amount, id } = props.scholarship
    const loading = props.isLoading
    const uniImageStore = useSelector((state: RootState) => state.localImageReducer.university)
    const imageURL = useMemo(() => {
        if (!university) return ''
        const a = university.toLowerCase().replace(" ", "");
        const image = 'logo/' + university.toUpperCase()
        return uniImageStore[a]?.[image] ?? defaultImage
    }, [uniImageStore, university])
    const coverage = useMemo(() => amount?.includes('-') ? parseInt(amount.split('-')[1]) : parseInt(amount), [amount])
    const _onClickHandler = useCallback(() => {
        props.onPress(id)
        logNavigateEvent(Routes.scholarship, Routes.scholarshipDetails, 'schoalrship_card')
    }, [id])
    return (
        <Wrapper htmlFor="scholarship-details-modal" onClick={_onClickHandler}>
            <Header isLoading={loading}>
                {amount &&
                    <ProgressBarWrapper>
                        <Text isLoading={loading} color={Colors.white} margin={{ right: Space.large }} fontSize={Size.medium} bold>
                            Coverage
                        </Text>
                        <CircleProgress textSize={"0.65rem"} value={coverage} height='45px' width='55px' activeColor='#50C878'/>
                    </ProgressBarWrapper>}
            </Header>
            <Body>
                <>
                    <ImageWrapper isLoading={loading}>
                        {!loading && <Image src={imageURL} />}
                    </ImageWrapper>
                    <Text isLoading={loading} fontSize={Size.large} semibold>
                        {name?.length > 45 ? name?.substring(0, 45) + "..." : name}
                    </Text>
                    {loading && <Text isLoading={true} style={{ width: '65%' }}/>}
                </>
                <div style={styles.footer}>
                    <Text isLoading={loading} color='#858585' fontSize={Size.thin_medium} semibold>
                        Offered at <span style={{ color: Colors.primary }}>{university}</span>
                    </Text>
                    {deadline &&
                        <div style={styles.deadline}>
                            <CalendarIcon fill={Colors.primary} height={15} width={15} />
                            <Text isLoading={loading} margin={{ left: Space.medium }} fontSize={'0.66rem'} color={Colors.black} fontWeight={600}>
                                {deadline}
                            </Text>
                        </div>}
                </div>
            </Body>
        </Wrapper>
    )
}

const Wrapper = styled.label`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${Colors.blueish};
    width: 90%;
    aspect-ratio: 1.7;
    margin: ${Space.medium};
    position: relative;
    box-sizing: border-box;
    border-color: #D9D9D9;
    border-style: solid;
    border-width: 1px;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        width: 300px;
        aspect-ratio: 1.5;

    }
    overflow: hidden;
`
const Header = styled.div<{ isLoading?: boolean }>`
    display: flex;
    flex: 0.55;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: center;
    flex-direction: column;
    margin-bottom: ${Space.small};
    background-color: ${props => props.isLoading ? 'transparent' : Colors.primary};
`

const Body = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    padding: ${Space.medium};
    padding-top: 0px;
    box-sizing: border-box;
    flex: 1;
    font-size: ${Size.thin_medium};
    padding-left: ${Space.largest};
    padding-top: 40px;
    justify-content: space-between;
`
const ImageWrapper = styled.div<{ isLoading?: boolean }>`
    border-radius: 32px;
    height: 64px;
    width: 64px;
    overflow: hidden;
    position: absolute;
    top: -40px;
    border-style: solid;
    border-width: 1px;
    background-color: ${Colors.grey};
    ${props => props.isLoading ? skeletonState : null}
`
const ProgressBarWrapper = styled.div`
    position: absolute;
    top: 22px;
    display: flex;
    margin-right: ${Space.large};
    right: 0;
`
const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`
type Style = {
    footer: React.CSSProperties;
    deadline: React.CSSProperties;
}
const styles: Style = {
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: Space.medium
    },
    deadline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}
export default ScholarshipItem;