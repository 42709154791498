import { BubbleContainer, bubbleContainerStyle } from "components/StyledComponents/BubbleContainer";
import React, { useMemo } from "react";
import HeaderTitle from "./HeaderTitle";
import Text from "components/Core/Text";
import { Size, Space } from "utils/constants";
import GoogleMap from "./GM";
import styled from "styled-components";
interface Props {
    isMobile: boolean;
    content: string;
    acronym?: string;
    loc?: {lat: string, long: string};
    name?: string;
}
const AboutUs: React.FC<Props> = ( { isMobile, content, acronym, loc, name }) => {
    const lat = useMemo(() => loc ? parseFloat(loc?.lat) : 0, [loc])
    const long = useMemo(() => loc ? parseFloat(loc?.long) : 0, [loc])
    
    return (
        <BubbleContainer style={isMobile ? bubbleContainerStyle.mobile : { padding: 0 }}>
        <div style={isMobile ? null : { padding: Space.large }}>
            <HeaderTitle text={'About ' +  (acronym ?? name)} />
            <Text semibold fontSize={isMobile ? Size.thin_medium : '0.85rem'} style={{ lineHeight: '22px' }}>
                {content}
            </Text>
        </div>
        {!isMobile && loc &&
            <MapContainer>
                <GoogleMap lng={long} lat={lat} name={name} />
            </MapContainer>}
    </BubbleContainer>
    )
}

const MapContainer = styled.div`
    width: 100%;
    aspect-ratio: 2.4;
    object-fit: cover;
`
export default AboutUs;