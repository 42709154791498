import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import Text from "components/Core/Text";
import 'components/css/common.css'

interface Props {
    lat: number;
    lng: number;
    name: string;
}
const GM: React.FC<Props> = ({ lat, lng, name}) => {
    const [isOpen, setIsOpen] = useState(false)
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      });
    if (!isLoaded) return
    return (
        <GoogleMap mapContainerClassName="map-container" center={{ lat, lng}} zoom={15}
        options={{mapTypeControl: false, streetViewControl: false,}}>
            <Marker position={{lat, lng}} onClick={() => setIsOpen(true)}>
            {isOpen && <InfoWindow onCloseClick={() => setIsOpen(false)}>
                    <Text fontWeight={400}>
                        {name}
                    </Text>
                </InfoWindow>}
            </Marker>
        </GoogleMap>
    )
}
export default GM;