import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs as TabsNav } from "react-tabs"
import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";
import Text from "./Text";
import '../css/common.css'
import { useMediaQuery } from "hooks/useMediaQuery";
import { capitalizeFirstLetter } from "utils/helpers";

interface Props {
    onTabChange?: (s: {ind: number, label?: string}) => void;
    tabsContainerStyle?: React.CSSProperties;
    tabStyle?: React.CSSProperties;
    tabs: string[];
    content?: React.ReactNode[];
}
const Tabs: React.FC<Props> = (props) => {
    const [selectedInd, setSelectedIndex] = useState(0)
    const isIpad = useMediaQuery(`(min-width: ${CustomBreakPoints.tablet}) and (max-width: ${CustomBreakPoints.desktop}) and (orientation : portrait)`)
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const titleSize = isMobile ? Size.thin_medium : (isIpad ? '0.8rem' : '0.9rem')
    const onChangeIndexHandler = (ind) => {
        setSelectedIndex(ind)
    }
    useEffect(() => {
        if (props.onTabChange) props.onTabChange({ ind: selectedInd, label: props.tabs[selectedInd] })
    }, [selectedInd])
    
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TabsNav style={styles.tabsStyle} onSelect={(ind) => onChangeIndexHandler(ind)}>
            <TabList style={{...styles.tabsList, ...props.tabsContainerStyle}}>
                {props.tabs.map((tab, index) => {
                    return (
                        <Tab key={tab} style={{...styles.tabStyle, ...props.tabStyle}} selectedClassName="selectedTab">
                            <Text color={selectedInd === index && Colors.white} fontSize={titleSize} bold>
                                {capitalizeFirstLetter(tab)}
                            </Text> 
                        </Tab>
                    )
                })}
            </TabList>
            {props.content?.map((content, index) => {
                return (
                    <TabPanel key={index}>
                        {content}
                    </TabPanel>
                )
            })}
        </TabsNav>
        </div>
    )
}

type Style = {
    tabsStyle: React.CSSProperties;
    tabsList: React.CSSProperties;
    tabStyle: React.CSSProperties;
}

const styles: Style = {
    tabsStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tabsList: {
        backgroundColor: Colors.blueish,
        borderRadius: 10,
        borderWidth: 0,
        display: 'flex',
        padding: '4px',
        width: '90%',
        overflowX: 'auto',
        alignSelf: 'center'
    },
    tabStyle: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        marginLeft: Space.small,
        marginRight: Space.small,
        padding: Space.medium,
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    }
}
export default Tabs;