import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
Type: {
    Athletic: {
        value: false,
        query: "Athletic",
    },
    Merit: {
        value: false,
        query: "Merit",
    },
    "Need-Based": {
        value: false,
        query: "Financial Aid",
    },
    "Work-Study": {
        value: false,
        query: "Work - Study",
    },
    Siblings: {
        value: false,
        query: "Sibling",
    },
    Dependents: {
        value: false,
        query: "Dependents",
    },
    Accomodation: {
        value: false,
        query: "Accomodation",
    },
    Loan: {
        value: false,
        query: "Loan",
    },
    Other: {
        value: false,
        query: "Other",
    },
},
Universities: {
    value: [],
    store: [],
    query: "universities",
},
Amount: {
    value: 100,
    query: "amount"
},
filtersActive: false,
diff: false,
}

const filterSlice = createSlice({
    name: 'filterEngine',
    initialState,
    reducers: {
        applyFilters: (_, { payload }) => {
           return { Type: payload.Type, Amount: payload.Amount, Universities: payload.Universities, filtersActive: true, diff: false }
        },
        setDiff: (state, { payload }) => {
            state.diff = payload
        },
        reset: () => {
            const i = { ...initialState }
            return i
        }
    }
})

export const { applyFilters, reset, setDiff } = filterSlice.actions
export default filterSlice.reducer