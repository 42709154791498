import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import Text from "../Core/Text";
import { ReactComponent as Graduation } from "assets/svgs/illustrations/graduation.svg";
import { ReactComponent as DownArrow } from "assets/svgs/icons/down-arrow.svg";
import { constructURL, goTo } from "utils/helpers";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ListingItems, Styles } from "utils/types";
import DropDown from "../DropDown";
import Button from "components/Core/Button";
import SearchEngine from "components/SearchEngine";
import { logNavigateEvent } from "tracking/google";

const searchItems: { label: string, value: ListingItems }[] = [{label: 'universities', value: ListingItems.University}, {label: 'scholarships', value: ListingItems.Scholarship}]
const FullScreenHero = () => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const isDesktop = useMediaQuery(`(min-width: ${CustomBreakPoints.desktop})`)
    const [searchType, setSearchType] = useState(searchItems[0])
    return (
      <Wrapper>
        <ContentWrapper>
            <InfoWrapper>
                <TextWrapper>
                    <Text color={Colors.secondary} fontSize={isMobile ? Size.larger : Size.xxxLarge} fontWeight={700}
                    style={styles.titleText(isMobile)} margin={{top: Space.larger}} >
                        Bridging Possibilities and Opportunities
                    </Text>
                    <Text style={styles.bodyText(isMobile)} fontWeight={500}>
                        Jusoor.io Provides Detailed Information About Universities, Scholarships, And Other Educational Opportunities In The Mena Region.
                    </Text>
                </TextWrapper>
                {isDesktop
                    && <SearchWrapper>
                            <InputContainer>
                                <SearchEngine
                                    placeholder="Something on your mind ?"
                                    overrideClasses="w-full"
                                    type={searchType.value}
                                    hideBorder
                                    rightcomponent={(
                                        <DropDownContainer>
                                            <DropDown isOneSided data={searchItems} icon={() => <DownArrow height={'20px'} width={'20px'} fill="white"/>} onSelectItem={(i) => setSearchType(searchItems[i])}/>
                                        </DropDownContainer>)}/>
                            </InputContainer>
                           
                        </SearchWrapper>}
                {!isMobile
                    && <DesktopButtonContainer isDesktop={isDesktop}>
                            <GetStartedButton />
                        </DesktopButtonContainer>}
            </InfoWrapper>
            <Graduation width={'80%'} height={'100%'} style={{ minWidth: '330px' }}/>
            {isMobile && <div style={{ marginTop: Space.large }}>
                            <GetStartedButton />
                        </div>} 
        </ContentWrapper>
      </Wrapper>
   )
}

const GetStartedButton = () => {
    const _onClickHandler = useCallback(() => {
        goTo(Routes.university, ['1'], true)
        logNavigateEvent(Routes.home, Routes.university, 'get_started')
    }, [])
    
    return (
        <Button color="accent" children='Get Started'
            wide onClick={_onClickHandler}/>)
}

const Wrapper = styled.div`
    background: rgb(51,83,95);
    background: linear-gradient(180deg, rgba(51,83,95,1) 0%, rgba(255,255,255,1) 100%);
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${Space.larger};
    margin-bottom: 2em;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        margin-bottom: 3em;
    }
`
const ContentWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        flex-direction: row;
        width: 80%;
    }
`
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        align-self: flex-start;
        justify-content: space-between;
        align-items: flex-start;
    }
    @media (min-width: ${CustomBreakPoints.desktop}) {
        margin-top: 6em;
        height: 100%;
    }
`
const TextWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        align-items: flex-start;
    }
`
const SearchWrapper = styled.div`
    width: 65%;
    margin-top: 4em;
    display: flex;
    align-items: center;
    border-radius: 10px;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`
const DropDownContainer = styled.div`
    display: flex;
    flex: 0 0 50%;
`
const DesktopButtonContainer = styled.div<{isDesktop: boolean}>`
    width: ${props => props.isDesktop ? '30%' : '50%'};
    margin-top: ${props => props.isDesktop ? '8em' : '4em'};
`

const styles: Styles = {
    bodyText: (isMobile: boolean) => ({
        textAlign: !isMobile ? 'left' : 'center',
        lineHeight: '24px',
        width: '90%',
        fontSize: isMobile ? Size.medium : Size.large_medium,
        color: Colors.white,
        marginTop: Space.larger
    }),
    titleText: (isMobile: boolean) => ({
        lineHeight: 1.5,
        textAlign: !isMobile ? 'left' : 'center'
    })

}
export default FullScreenHero;