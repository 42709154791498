import ScrollView from "components/Core/ScrollView";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import React, { useMemo } from "react";
import { SwiperSlide } from "swiper/react";
import ValuedLabel from "./ValuedLabel";
import { Ranking } from "utils/types";
import { Divider } from "components/StyledComponents/Divider";
import HeaderTitle from "./HeaderTitle";

const RankingCarousel = ({ rankings, limit, isMobile }) => {
    const count = useMemo(() => Math.ceil(rankings.length / limit), [limit]) 
    const rankingRows = useMemo(() => rankings?.length > 0 ? Array.from({ length: count === 0 ? 1 : count}, (_, i) => i) : [], [rankings, count])
    return (
        <>
        {!isMobile && <HeaderTitle text='Ranking' />}
        <ScrollView showPagination>
            {rankingRows.map((ind1) => {
                const start = ind1 * limit
                const end = start + limit
                const sliced = rankings?.slice(start, end)
                return (
                    <SwiperSlide key={ind1}>
                        <FlexDirection.Row style={{ justifyContent: 'center' }}>
                            {sliced.map((ranking: Ranking, ind2: number) => {
                                return (
                                    <>
                                    <ValuedLabel forceSmallText additionalContent={ranking.metric} label={ranking.rank} value={ranking.region} displayNumber />
                                    {_displayDivider(sliced.length, ind2) && <Divider />}
                                    </>
                                )
                            })}
                        </FlexDirection.Row>
                    </SwiperSlide>
                )
            })}
        </ScrollView>
        </>
    )
}
const _displayDivider = (totalLength: number, index: number) => {
    return index !== totalLength - 1 && totalLength > 1
}
export default RankingCarousel;