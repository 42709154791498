import { css, keyframes } from "styled-components"

const pulse = keyframes`
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
`

export const skeletonState = css`
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: ${pulse} 2s infinite ease-out;
`