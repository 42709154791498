import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Colors, Size } from "utils/constants";
import Text from "./Core/Text";

interface Props {
    height: string;
    width: string;
    activeColor?: string | Colors;
    secondaryColor?: string | Colors;
    value: number;
    hideLabel?: boolean;
    textColor?: string | Colors;
    textSize?: Size | string;
    customText?: string;
}
const CircleProgress: React.FC<Props> = ({ textSize, textColor, hideLabel, height, width,  activeColor, customText, value }) => {
    const [amount, setAmount] = useState(0)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setAmount(value)
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    }, [value])
    return (
        <div style={{ height, width }}>
            <CircularProgressbarWithChildren value={amount} maxValue={100} styles={{
                path: {
                    strokeLinecap: 'round',
                    stroke: activeColor ?? Colors.gold,
                    strokeWidth: 10,
                    transition: 'stroke-dashoffset 0.5s ease 0s'
                },
                background: { fill: Colors.white }
            }} strokeWidth={12} background={true}>
             { hideLabel ?
                null : 
                <Text bold fontSize={textSize ?? Size.thin} color={textColor ?? Colors.black}>
                    {customText ?? amount}%
                </Text>}
            </CircularProgressbarWithChildren>
        </div> 
    )
}

export default CircleProgress;