import React from "react";
import Lottie from "lottie-react"
import loader from  "assets/lottie/loading.json"
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import Text from "./Text";
import { useMediaQuery } from "hooks/useMediaQuery";
import { CustomBreakPoints, Size } from "utils/constants";

interface Props {
    height?: number;
    width?: number;
}
const Loader: React.FC<Props> = ({ height, width }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    return (
        <FlexDirection.Column style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Lottie animationData={loader} loop style={{ height: height ?? 300, width: width ?? 300 }} />
            <Text style={{ textAlign: 'center' }} children="Please Be patient. We are summoning Knowledge..." semibold={isMobile} fontSize={isMobile ? Size.large_medium : Size.large}/>
        </FlexDirection.Column>
        
    )
}

export default Loader