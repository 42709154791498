import { skeletonState } from 'components/StyledComponents/SkeletonAnimation';
import React from 'react';
import styled from 'styled-components';
import { Colors, Size, Space } from 'utils/constants';

interface TextProps {
    id?: string;
    bold?: boolean;
    semibold?: boolean;
    color?: Colors | string;
    fontWeight?: number;
    style?: React.CSSProperties;
    children?: any;
    fontSize?: Size | string;
    isLoading?: boolean;
    margin?: { top?: string | Space, left?: string | Space, right?: string | Space, bottom?: string | Space, all?: string | Space}
}
const Text: React.FC<TextProps> = (props) => {

    return (
        <TextWrapper id={props.id}  {...props}>
            {props.isLoading ? '' : props.children}
        </TextWrapper>)
}
const TextWrapper = styled.div<TextProps>`
    color: ${props => props.color ?? Colors.black};
    font-size: ${props => props.fontSize ?? Size.large_medium};
    margin: ${props => props?.margin?.all};
    margin-top: ${props => props?.margin?.top};
    margin-bottom: ${props => props?.margin?.bottom};
    margin-left: ${props => props?.margin?.left};
    margin-right: ${props => props?.margin?.right};
    font-weight: ${props => props.fontWeight ?? (props.bold ? '800' : (props.semibold ? '600' : undefined))};
    min-height: ${props =>  props.isLoading ?  '10px': null};
    min-width: ${props =>  props.isLoading ?  '100px': null};
    ${props =>  props.isLoading ? skeletonState : null}
    

`
export default Text;