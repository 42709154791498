import React from "react";
import Text from "./Text";
import { Size } from "utils/constants";

interface Props {
    children: any;
    title: string;
    subtitle?: string;
}
const Collapsible: React.FC<Props> = ({ children, title, subtitle }) => {
    return (
        <div className="collapse collapse-arrow open bg-base-200">
            <input type="checkbox" /> 
            <div className="collapse-title text-xl font-medium">
                <Text fontWeight={600} fontSize={Size.large_medium}>
                    {title}
                </Text>
                <Text fontSize={Size.medium}>
                    {subtitle}
                </Text>
            </div>
            <div className="collapse-content"> 
                {children}
            </div>
        </div>
    )
}

export default Collapsible;