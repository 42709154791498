import React, { useEffect, useState } from 'react';
import { HeroSectionVariants, ListingItems } from 'utils/types';
import { ReactComponent as Illustration } from 'assets/svgs/university_building.svg';
import ItemsListing from './ItemsListing';
import { UniversityListingResponse } from 'types/University';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { CustomBreakPoints } from 'utils/constants';
import { getUniversityList } from 'API/webAPI';

interface Props {
    page: string;
    size: string;
}
const UniversitiesListing: React.FC<Props> = ({ page, size }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.phone})`)
    const [data, setData] = useState<UniversityListingResponse | undefined>();
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        getUniversityList(size, page)
            .then(res => {
                setLoading(false)
                setData(res)
            })
            .catch(err => {
                setLoading(false)
                console.log('err', err)
            })
    }, [size, page])

    const universities = data?.results
    const pagination = { next: data?.next, prev: data?.prev, count: data?.count, params: '', current: parseInt(page ?? '1'), size: parseInt(size)}
    return (
        <ItemsListing
            paginationInfo={pagination}
            loading={loading}
            items={universities}
            heroContent={{
                title: "University Hub",
                description: "Explore detailed descriptions about different universities, their courses and specializations, admission requirements, scholarships and more.",
                isReversed: false,
                variant: HeroSectionVariants.ScholarshipVariant1,
                illustration: (st) => <Illustration style={{...st, height: !isMobile ? '40%' : '100%', width: !isMobile ? '40%' : '100%'}} />
            }}
            type={ListingItems.University}
        />
    )
}

export default UniversitiesListing;
