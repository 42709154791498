import FeaturedUnisSlider from 'components/FeaturedUnis';
import { Colors, CustomBreakPoints, Routes, Size, Space, email_regex } from 'utils/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Scholarship } from "assets/svgs/illustrations/scholarship_student.svg";
import { ReactComponent as University } from "assets/svgs/illustrations/university_building.svg";
import FullScreenHero from 'components/Hero/FullScreenHero';
import IllustratedView from 'components/IllustratedView';
import { goTo } from 'utils/helpers';
import Statistics from 'components/Statistics';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Text from 'components/Core/Text';
import TextInput from 'components/Core/TextInput';
import { Button } from 'react-daisyui';
import { ReactComponent as CheckMark } from 'assets/svgs/icons/check-circle.svg';
import { getStats, subscribeEmail } from 'API/webAPI';
import { logNavigateEvent, logSuccessfulSubscribe } from 'tracking/google';

const HomePage = () => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    return (
        <Wrapper>
            <FullScreenHero />
            <FeaturedUnisSlider />
            <>
              <Stat isMobile={isMobile}/>
              <IllustratedView
                illustration={(style) => <University style={style}/>}
                button={{
                  text: 'Explore',
                  onPress: () => {
                    goTo(Routes.university, [])
                    logNavigateEvent(Routes.home, Routes.university, 'explore_button')
                  }
                }}
                title={"Find Affordable Universities"}
                subtitle="Explore detailed descriptions about different universities, their courses and specializations, admission requirements, scholarships and more. Join us in discovering the best educational opportunities that are available"
                isSecondary
              />

              <IllustratedView
                title={"Match With Scholarships"}
                subtitle="Jusoor.io is the perfect platform to discover all about scholarships and grants available for higher education. We provide an extensive list of scholarship opportunities from a variety of sources that can help you pay for college."
                button={{
                  text: "Discover",
                  onPress: () => {
                      goTo(Routes.scholarship, [])
                      logNavigateEvent(Routes.home, Routes.scholarship, 'discover_button')
                    }
                  }}
                isReversed
                illustration={(styles) => <Scholarship style={styles} />}
              />
              <StayTuned isMobile={isMobile}/>
            </>
        </Wrapper>
    )
}

const Stat = ({ isMobile }) => {
  const [stat, setData] = useState<{university_count: number, scholarship_count: number, majors_count: number, trending: string}>()
  const items = useMemo(() => {
    const t = [
      {title: 'Universities', value: stat?.university_count, desc: 'Across Lebanon'},
      {title: 'Scholarships', value: stat?.scholarship_count, desc: 'Diverse Aid'},
      {title: 'Majors', value: stat?.majors_count, desc: 'Diverse Disciplines'}
    ]
    return t
  }, [stat])

  const getStatistics = useCallback(async () => {
    const info = await getStats()
    setData(info)
  }, [])

  useEffect(() => {
    getStatistics()
  }, [])

  return (
    <StatisticView>
      <InternalWrapper>
        <Text fontSize={isMobile ? Size.larger : Size.xxLarge}
          fontWeight={700}
          color={Colors.secondary}
          style={{textAlign: isMobile ? 'center' : 'left'}}
          margin={{bottom: Space.large}}>
            Lebanon's Education Landscape
        </Text>
        <StatisitcWrapper>
          <Statistics style={{ flex: isMobile ? null : 0.6 }}
            items={items}/>
          {!isMobile && <Statistics items={[{title: '↗︎ Trending Major', value: stat?.trending, desc: 'High Demand'}]} />}
        </StatisitcWrapper>
      </InternalWrapper>
    </StatisticView>
  )
}

const StayTuned = ({ isMobile }) => {
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [status, setStatus] = useState<"idle" | "loading" | "success">("idle")
    const onSubmit = useCallback(async () => {
      setStatus('loading')
      const response = await subscribeEmail(email)
      if (response?.status === 200) {
        setStatus('success')
        logSuccessfulSubscribe(Routes.home)
      } else {
          if (response?.status === 406) setError('This email has already been subscribed.')
          else setError("Uh oh! Something went wrong. Please try again later.")
          setStatus('idle')
      }
    }, [email])

    const onInputChange = useCallback(() => {
      setError("")
      const input = document.getElementById('email') as HTMLInputElement | null;
      setEmail(input.value)
    }, [])

    const onInputBlur = useCallback(() => {
      if (email.length > 0 && !email.match(email_regex)) setError('Invalid Email')
    }, [email])
    const buttonDisabled = error?.length > 0 || !email.match(email_regex)
    return (
        <CutSection>
          <StayTunedWrapper isMobile={isMobile}>
            <div style={{ flexDirection: 'column', display: 'flex', flex: 0.25 }}>
              <Text fontSize={isMobile ? Size.larger : Size.xxLarge} bold
                style={{ textAlign: isMobile ? 'center' : 'left', marginBottom: Space.small }} color={Colors.primary}>
                Stay Tuned
              </Text>
              <Text fontSize={Size.large_medium} style={{ textAlign: isMobile ? 'center' : 'left' }}
                margin={{ bottom: Space.largest }} color={Colors.primary}>
                This is just the start. Awesome things will come soon. Subscribe to stay up-to-date with our latest services.
              </Text>
            </div>
            <InputWrapper isMobile={isMobile}>
              {error && <Text color='red' children={error}/>}
              {status !== "success" ?
                <TextInput
                  id="email"
                  placeholder="Enter your email"
                  rightcomponent={
                    <Button children="Subscribe" color="primary" loading={status === 'loading'}
                      style={{ height: '3rem' }} disabled={buttonDisabled} onClick={onSubmit}/>}
                  onInput={onInputChange}
                  onBlur={onInputBlur}
                  style={{ width: '50%' }}
                />
              : <SubmitResultWrapper>
                  <CheckMark height={100} width={100}/>
                  <Text color={Colors.primary} style={{ fontWeight: 'bold', textAlign: 'center' }} fontSize={Size.xLarge} children={'Subscribed Successfully'}/>
                </SubmitResultWrapper>}
            </InputWrapper>
          </StayTunedWrapper>
        </CutSection>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  height: 100%;
`;

const StatisticView = styled.div`
  background-color: ${Colors.primary};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Space.larger};
`
const InternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-self: center;
  margin-top: ${Space.medium};
  margin-bottom: ${Space.medium};
`
const StatisitcWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const CutSection = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 1em 3em 3.2em 2em;
  width: 100%;
  background-color: white;
`
const StayTunedWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: center;
  justify-content: ${props => props.isMobile ? 'center' : 'center'};
  min-height: ${props => props.isMobile ? null : '180px'};
`
const InputWrapper = styled.div<{ isMobile: boolean }>`
  width: ${props => !props.isMobile ? '50%' : '100%' };
  flex: 0.3;
  margin-left: ${props => !props.isMobile ? '6em' : null};
`
const SubmitResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export default HomePage;
