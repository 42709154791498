import { Colors, Routes, Size, Space, defaultImage } from 'utils/constants';
import { SwiperItemProps } from 'utils/types';
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { extractUniImages, goTo } from 'utils/helpers';
import { logNavigateEvent } from 'tracking/google';

export const SwiperItem: React.FC<SwiperItemProps> = ({ item, footerStyle, itemStyle }) => {
    const uniImageStore = useSelector((state: RootState) => state.localImageReducer.university)
    const imageURL = useMemo(() =>  extractUniImages(item?.acronym, ['thumbnail'])?.[0] ?? defaultImage, [uniImageStore, item?.acronym])
    const _onClickHandler = useCallback(() => {
        const acronym = item?.acronym?.toLocaleLowerCase()
        goTo(Routes.universityDetails, [acronym])
        logNavigateEvent(Routes.home, Routes.universityDetails + `/${acronym}`, 'top_picks')
    }, [item?.acronym])
    
    return (
        <Wrapper style={itemStyle} onClick={_onClickHandler} >
            <BGImage src={imageURL}/>
            <ItemFooter style={footerStyle}>
               {item.name}
            </ItemFooter>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background-color: ${props => props.color};
    justify-content: flex-end;
    border: 2px solid ${Colors.primary};
    box-sizing: border-box;
`

const BGImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const ItemFooter = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    z-index: 100;
    color: #fff;
    min-height: 50px;
    right: 2px;
    left: 2px;
    justify-content: center;
    align-items: center;
    font-size: ${Size.large_medium};
    font-weight: 700;
    background-color: rgba(0,0,0,0.4);
    padding: ${Space.medium};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`