import errorSvg from "assets/svgs/illustrations/error.svg";
import Button from "components/Core/Button";
import Text from "components/Core/Text";
import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import styled from "styled-components";
import { CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import { goTo } from "utils/helpers";

const ErrorPage = () => {
  const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
  return (
    <Wrapper>
      <Svg src={errorSvg}/>
      <Text fontWeight={600} fontSize={!isMobile ? Size.xLarge : Size.large} margin={{ bottom: Space.medium }}>
        Ops! Something Went Wrong :(
      </Text>
      <Text
        margin={{ bottom: Space.large }}
        fontSize={isMobile ? Size.large_medium : Size.large}
        style={{ textAlign: 'center', width:  '80%' }}>
        We apologize, but an unexpected error has occurred while processing your request.
        Our team has been notified and is actively working to resolve the issue. Please try again later.
        Thank you for your patience and understanding.
      </Text>
      <Button children="Go Back to Home Page" onClick={() => goTo(Routes.home, [], true)} />
    </Wrapper>
  );
};

export default ErrorPage;

const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Svg = styled.img`
  display: flex;
  height: 60%;
  width: 60%;
  min-width: 250px;
  min-height: 250px;
  @media (max-width: ${CustomBreakPoints.tablet}){
    height: 40%;
  }
`;