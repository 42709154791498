import Text from "components/Core/Text";
import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { logNavigateEvent } from "tracking/google";
import { Colors, CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import { PartialMajor } from "utils/types";

interface Props {
    imageURL?: string;
    major: PartialMajor;
    acronym: string;
    onSelectMajor: (ind: number) => void;
}
const MajorItem: FC<Props>= ({ imageURL, major, onSelectMajor, acronym }) => {
    const _onClickHandler = useCallback(() => {
        onSelectMajor(major.id)
        logNavigateEvent(Routes.universityDetails + `/${acronym?.toLocaleLowerCase()}`, `major-${major.id}`, 'major_card')
    }, [major.id])
    return (
        <label htmlFor="major-details-modal" onClick={_onClickHandler}>
            <ImageContainer>
                {imageURL && <Image src={imageURL}/>}
                <Text fontSize={Size.large_medium} color={Colors.white} bold style={{ zIndex: 1000 }}>
                    {major.name}
                </Text>
                {major.level === 'Graduate' &&
                    <Tag>
                        <Text fontWeight={800} fontSize={Size.thin_medium}>
                            Graduate
                        </Text>
                    </Tag>}
            </ImageContainer>
        </label>
    )
}

const ImageContainer = styled.div`
    height: 100%;
    aspect-ratio: 1.3;
    overflow: hidden;
    background-color: #000;
    border-radius: 12px;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    padding: ${Space.small};
    @media (min-width: ${CustomBreakPoints.phone}) {
        aspect-ratio: 2.2;
    }
`
const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.55;
`
const Tag = styled.div`
    position: absolute;
    right: 0px;
    background-color: gold;
    top: 0px;
    padding: ${Space.small};
    border-bottom-left-radius: 12px
`
export default MajorItem;