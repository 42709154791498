import ig from "assets/svgs/social-media/ig.svg";
import linkedin from "assets/svgs/social-media/linkedin.svg";
import { SocialMediaLink } from "./Core/SocialMediaLink";
import React from "react";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import Text from "./Core/Text";
import { useMediaQuery } from "hooks/useMediaQuery";
import Button from "./Core/Button";
import { goTo } from "utils/helpers";

const Footer = () => {
  const isMobile = useMediaQuery(`(max-width:${CustomBreakPoints.tablet})`);
  return (
    <>
      <Container id="footer">
        <InnerContainer>
          <InnerContainerHeader>
            <Header>Proudly built by the Jusoor.io Team</Header>
          </InnerContainerHeader>
          <InnerContainerParagraph>
            Your no-cost partner in finding the perfect major and university through innovative digital solutions.
          </InnerContainerParagraph>
          <Divider />

          <Row>
            <div style={{flexDirection: isMobile ? 'column-reverse' : 'column', display: 'flex'}}>
              <Links>
            <Logo onClick={() => goTo(Routes.home, [], true)}>
              <Text fontSize={isMobile ? Size.larger : Size.xLarge} fontWeight={700} color={Colors.secondary}>
                Jusoor.io
              </Text>
            </Logo>
            <SocialMediaLinksContainer>
              <SocialMediaLink
                website={"https://instagram.com/jusoor.io"}
                svg={ig}
                height="20px"
              />
              <SocialMediaLink
                website={"https://www.linkedin.com/company/jusoor-io/"}
                svg={linkedin}
                height="20px"
              />
            </SocialMediaLinksContainer>
              </Links>
            </div>
            {!isMobile && 
              <Input>
                <ButtonRow>
                  <Button style={{ marginRight: "20px" }} className="btn-outline" children="Contact Us" onClick={() => goTo(Routes.contactUs, [])}/>
                </ButtonRow>
              </Input>}
          </Row>
        </InnerContainer>
      </Container>
    </>
  );
};

export default Footer;

const SVG = styled.img`
  width: 100%;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  border-radius: 10px;
`;

const Container = styled.div`
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.primary};
  padding-top: ${Space.medium};
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  z-index: 10000;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Space.large};
  width: 80%;
  justify-content: center;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    align-items: flex-start;
  }
`;

const InnerContainerHeader = styled.div`
  color: ${Colors.white};
  font-weight: 800;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: ${Space.large};
  font-size: ${Size.large};
`;

const Header = styled.p`
  color: #f1f1f1;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: -5px;
  font-size: ${Size.large};
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;

const InnerContainerParagraph = styled.div`
  color: ${Colors.white};
  letter-spacing: 1px;
  max-lines: 2;
  text-align: left;
  line-height: 20px;
  font-size: ${Size.thin_medium};
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;

const Divider = styled.div`
  margin-top: ${Space.largest};
  margin-bottom: ${Space.largest};
  border-bottom: 2px solid ${Colors.white};
  width: 100%;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  width: 100%;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    order: 2;
    justify-content: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    justify-content: center;
    width: 100%;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Space.largest};
  justify-content: center;
  align-items: center;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${Space.large};
  }
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SocialMediaLinksContainer = styled.div`
  margin-right: ${Space.largest};
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    margin-right: 0px;
  }
`;

const Logo = styled.div`
  margin-bottom: ${Space.larger};
  &:hover {
    filter: brightness(0) saturate(100%) invert(75%) sepia(73%) saturate(0%)
      hue-rotate(163deg) brightness(98%) contrast(101%);
  }
`;

// type Styles = {
//   input: React.CSSProperties;
// };
// const styles: Styles = {
//   input: {
//     border: 0,
//     outline: 0,
//     marginTop: "10px",
//     marginBottom: "20px",
//     /* double size button */
//     backgroundColor: "transparent",
//     width: "20vw",
//     borderBottomStyle: "solid",
//     borderBottomWidth: "3.5px",
//     borderBottomColor: Colors.white,
//     textAlign: "left",
//   },
// };
