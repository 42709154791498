import styled from "styled-components";
import { Colors, Space, CustomBreakPoints, Size, email_regex } from "utils/constants";
import React, { useCallback, useMemo, useReducer } from "react";
import Button from "./Core/Button";
import Text from "./Core/Text";
import DropDown from "./DropDown";
import { ReactComponent as DownArrow } from "assets/svgs/icons/down-arrow.svg";
import TextInput from "./Core/TextInput";
import { sendFeedback } from "API/webAPI";

type Action = {
  type: string,
  data?: string,
  input?: 'name' | 'email' | 'message'
  error?:  { name?: string, email?: string, message?: string }
}
const reducer = (state, action: Action) => {
  switch(action.type) {
    case 'set-input':
      return { ...state, [action.input]: action.data, error: { ...state.error, [action.input]: null } }
    case 'set-input-error':
      return { ...state, error: { ...state.error, ...action.error } }
    case 'set-option':
      return { ...state, option: action.data }
    case 'set-load':
      return { ...state, isLoading: !state.isLoading }
  }
}
const options = [{label: 'Student', value: 'student'}, {label: 'Potential Partner', value: 'potential-partner'}]

const ContactForm = ({ onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, {
    name: null,
    email: null,
    message: null,
    error: null,
    isLoading: false,
    option: options[0]?.label
  })

  const { name, email, message, error, option, isLoading } = state

  const handleInputChange = useCallback((type: 'email' | 'name' | 'message') => {
    const input = document.getElementById(type) as HTMLInputElement | null;
    dispatch({ input: type, type: 'set-input', data: input.value })
  }, []) 
  const handleInputBlur = useCallback((type: 'email' | 'name' | 'message', input?: string) => {
    if (!input || input.length === 0) dispatch({ type: 'set-input-error', error: { [type]: 'This input is required' } })
    if (type === 'email' && !input.match(email_regex)) dispatch({ type: 'set-input-error', error: { [type]: 'Please enter a valid email' } })
  }, []) 

  const isDisabled = useMemo(() => {
    const errors = Object.values(error ?? {}).filter(v => v !== null)
    return !name || !email || !message || errors.length !== 0
  },[error, name, message, email])

  const onSelectOption = useCallback((i) => dispatch({ type: 'set-option', data: options[i].label}), [])
  const onSend = useCallback(async () => {
    try {
      dispatch({ type: 'set-load' })
      const response = await sendFeedback(name, email, message, option)
      dispatch({ type: 'set-load' })
      if (response) onSuccess()
    } catch (err) {
      console.log('failed to send feedback', err)
    }
  }, [name, message, email, option, onSuccess])

  return (
    <Form>
      <FormTitle>
        Send us a message
      </FormTitle>
      <FormSubtitle>
        If you have any inquiry or uncertainty, do not hesitate to reach out to
        us and send an email!
      </FormSubtitle>
      <FormInputDiv style={{ width: '100%', overflow: 'visible' }}>
        <TextInput
            disabled
            value={'I am a '}
            className="w-full" 
            rightcomponent={(
              <DropDown
                isOneSided
                data={options}
                icon={() => <DownArrow height={'20px'} width={'20px'} fill="white"/>}
                onSelectItem={onSelectOption}/>
            )}
            hideBorder
            bgColor="#e0e0e0"
        />
      </FormInputDiv>
      <FormInputDiv>
       {error?.name &&
          <Text color="red" fontSize={Size.thin_medium}>
            {error.name}
          </Text>}
        <FormInput
          id='name'
          maxLength={40}
          placeholder="Enter your name"
          onInput={() => handleInputChange('name')}
          onBlur={() => handleInputBlur('name', name)}/>
      </FormInputDiv>
      <FormInputDiv>
        {error?.email &&
          <Text color="red" fontSize={Size.thin_medium}>
            {error.email}
          </Text>}
        <FormInput
          id='email'
          maxLength={40}
          placeholder="Enter your email"
          onInput={() => handleInputChange('email')}
          onBlur={() => handleInputBlur('email', email)}/>
      </FormInputDiv>
      <FormInputDiv>
        {error?.message &&
          <Text color="red" fontSize={Size.thin_medium}>
            {error.message}
          </Text>}
        <FormTextArea
          id='message'
          maxLength={400}
          placeholder="Enter your message"
          onInput={() => handleInputChange('message')}
          onBlur={() => handleInputBlur('message', message)}
        />
      </FormInputDiv>
      <BottomRow>
        <Button loading={isLoading} onClick={onSend} children="Send" color="primary" style={{ width: '40%' }} disabled={isDisabled}/>
      </BottomRow>
    </Form>
  );
};

export default ContactForm;

const Form = styled.div`
  width: 100%;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    width: 50%;
  }
`;
const FormTitle = styled.div`
  font-size: ${Size.large};
  font-weight: 600;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    font-size: ${Size.large_medium};
    margin-top: ${Space.medium};
  }
`;
const FormSubtitle = styled(FormTitle)`
  font-size: ${Size.thin_medium};
  font-weight: 400;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    font-size: ${Size.thin_medium};
  }
`;
const FormInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Space.large};
  margin-bottom: ${Space.large};
  width: 100%;
  font-size: ${Size.medium};
`;
const FormInput = styled.input`
  color: ${Colors.black};
  background-color: #e0e0e0;
  border: none;
  width: 100%;
  padding-top: ${Space.large};
  padding-bottom: ${Space.large};
  padding-left: 1em;
  border-radius: 5px;
  font-size: ${Size.large_medium};
  @media (max-width: ${CustomBreakPoints.tablet}) {
    width: 100%;
    font-size: ${Size.medium};
  }

  &::placeholder {
    font-size: ${Size.thin_medium};
  }
`;

const FormTextArea = styled.textarea`
  color: ${Colors.black};
  resize: none;
  display: flex;
  background-color: #e0e0e0;
  border: none;
  width: 100%;
  min-height: 20vh;
  padding-top: ${Space.large};
  padding-bottom: ${Space.large};
  padding-left: 1em;
  border-radius: 5px;
  font-size: ${Size.medium};
  @media (min-width: ${CustomBreakPoints.desktop}) {
    font-size: ${Size.large_medium};
  }
  &::placeholder {
    font-size: ${Size.thin_medium};
  }
`;

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* border: 2px solid ${Colors.black}; */
  justify-content: flex-end;
  align-items: flex-end;
`;
