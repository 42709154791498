import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperItem } from "./SwiperItem";
import { useMediaQuery } from "hooks/useMediaQuery";
import { Autoplay, Navigation, Pagination } from "swiper";
import { getFeaturedUniversities } from "API/webAPI";
import { skeletonState } from "./StyledComponents/SkeletonAnimation";
import { generateSequence } from "utils/helpers";
import styled from "styled-components";
import "swiper/css";
import 'swiper/css/navigation';
import "components/css/swiper-navigation.css"

const reducer = (_, action) => {
    switch(action.type) {
        case 'fetch-start':
            return { loading: true, error: null, universities: [] }
        case 'fetch-done':
            return { loading: false, error: null, universities: action.data }
        case 'fetch-failed':
            return { loading: false, error: action.error, universities: [] }
    }
}
const FeaturedUnisSlider = () => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.phone})`)
    const swiperRef = useRef(null)
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        error: null,
        universities: []
    });
    const { loading, universities } = state
    const data = useMemo(() => loading ? generateSequence(6) : universities, [loading])
    const onInit = useCallback(async () => {
        try {
            dispatch({ type: 'fetch-start' })
            swiperRef.current.swiper.autoplay.stop()
            const results = await getFeaturedUniversities()
            swiperRef.current.swiper.autoplay.start()
            dispatch({ type: 'fetch-done', data: results })
        } catch(err) {
            dispatch({ type: 'fetch-failed', error: err })
        }
    }, [])

    useEffect(() => {
        onInit()
    }, [])

    return (
        <Wrapper>
            <div style={{ marginBottom: Space.largest, marginLeft: isMobile ? Space.largest : '10%'}}>
                <Title isMobile={isMobile} color={Colors.primary}>Top Picks</Title>
            </div>
            <SwiperContainer
                ref={swiperRef}
                slidesOffsetAfter={20}
                centeredSlides={isMobile}
                slidesPerView={1.2}
                direction='horizontal'
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                spaceBetween={15}
                navigation={!loading && !isMobile}
                breakpoints={{
                    840: { slidesPerView: 4.3, spaceBetween: 40 },
                    640: { slidesPerView: 1.8, spaceBetween: 25 },
                }}
                >
                 {data?.map((item, index) => (
                    <Slide loading={loading.toString()} key={index}>
                        {loading ? null : <SwiperItem item={item}/>}
                    </Slide>
                ))}
            </SwiperContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 30vh;
    max-height: 400px;
    width: 100%;
    padding-bottom: ${Space.largest};
    box-sizing: content-box;
    background-color: ${Colors.secondary};
    @media (min-width: ${CustomBreakPoints.tablet}) {
        align-self: center;
        height: 50vh;
    }
`
const Text = styled.div`
    font-size: ${Size.medium};
    color: ${(props) => props.theme.color};
    text-align: left;
    width: 60%;
    margin-top: ${Space.small};
    margin-bottom: ${Space.small};
    @media (max-width: ${CustomBreakPoints.phone}) {
       width: initial;
       margin-top: initial;
       margin-bottom: initial;
    }
`
const Title = styled(Text)<{isMobile: boolean}>`
    font-size: ${props => props.isMobile ? Size.larger : Size.xLarge};
    font-weight: 700;
    color: ${Colors.primary};
`
const SwiperContainer = styled(Swiper)`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 90%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: 8px;
    width: 100%;
    padding-left: 10%;
    @media (max-width: ${CustomBreakPoints.phone}) {
        width: 100%;
        flex: 1;
        padding-left: initial;
    }
`
const Slide = styled(SwiperSlide)<{loading?: string}>`
    display: flex;
    align-items: center;
    border-radius: 16px;
    ${props => props.loading === 'true' ? skeletonState : null}
`
export default FeaturedUnisSlider;