import React from 'react';
import { Button as B, ButtonProps } from 'react-daisyui';
import styled from 'styled-components';
import { logOnButtonClick } from 'tracking/google';
import { Colors } from 'utils/constants';

export const Button: React.FC<ButtonProps> = (props) => {
    return (
        <CustomButton {...props} onClick={(e) => {
            props.onClick(e)
            logOnButtonClick(props.children)
        }}/>
    )
}

const CustomButton = styled(B)`
    min-height: 1em;
    height: 2.7rem;
    color: ${props => props.color === 'secondary' ? Colors.primary : '#fff'};
    text-transform: capitalize;
`

export default Button;