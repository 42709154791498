
export enum Colors {
    primary = "#33535f",
    whiteYellow = "#F8F1D0",
    secondary = "#ffffff",
    white = "#ffff",
    darkWhite = "#f1f1f1",
    gold = "#FFDF01",
    black= "#000",
    offWhite = "#D9D9D9",
    greyWhite = "#FAF9F6",
    grey="#f6f8fc",
    purple = "#ad58b2",
    darkPurple = "#a253a7",
    blueish = "#edf0f5"
}

export enum Size {
    thinner='0.35rem',
    thin='0.5rem',
    thin_medium='0.75rem',
    medium = '0.85rem',
    large_medium='0.95rem',
    large='1.25rem',
    larger='1.5rem',
    xLarge='1.75rem',
    xxLarge='2rem',
    xxxLarge='2.25rem'
}

export enum Space {
    smallest='0.125em',
    small='0.25em',
    medium='0.5em',
    large='1em',
    larger='1.25em',
    largest='1.5em'
}

export enum CustomBreakPoints {
    tablet='630px',
    // tablet = '798px',
    phone='520px',
    pad = '',
    smallDevice = '380px',
    desktop = '1024px'
}

export const randomColors = [
    { bgColor: "rgba(141, 235, 141, 0.424)", textColor: "rgb(0, 200, 0)"},
    { bgColor: "rgba(38, 74, 205, 0.424)", textColor: "rgb(2, 47, 207)" },
    { bgColor: "rgba(133, 71, 195, 0.424)", textColor: "rgb(100, 0, 200)" },
    { bgColor: "rgba(223, 198, 71, 0.424)", textColor: "rgb(227, 179, 4)" },
]

export enum Routes {
    university = '/university-explore',
    scholarship = '/scholarship-explore',
    universityDetails = '/university-details',
    scholarshipDetails = '/scholarship-details',
    home = '/',
    contactUs = '/contact-us',
    error = '/error',
    notfound = '/404'
}

export const defaultImage = "https://firebasestorage.googleapis.com/v0/b/jusoor-456e3.appspot.com/o/university%2Fdefault.webp?alt=media&token=47c42b6f-af51-49bb-a8cd-7c03da9140a8"

export const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/