import { BubbleContainer } from "components/StyledComponents/BubbleContainer";
import React, { useEffect, useMemo, useState } from "react";
import HeaderTitle from "./HeaderTitle";
import Button from "components/Core/Button";
import { Colors, Space } from "utils/constants";
import { ReactComponent as LinkIcon } from "assets/svgs/icons/link-icon.svg";
import { ContactInfo as ContactType } from "utils/types";
import CustomTab from "components/Core/Tabs";
import RowValuedLabel from "./RowValuedLabel";
import GoogleMap from "./GM";

interface Props {
    isMobile?: boolean;
    website?: string;
    data?: ContactType[];
    tabs?: string[];
    loc?: {lat: string, long: string}
    name?: string
}
const ContactInfo: React.FC<Props> = ({ isMobile, website, data, tabs, loc, name }) => {
    const [tabIndex, setTabIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const contactInfo = useMemo(() => data[tabIndex], [tabIndex]) 
    const lat = useMemo(() => loc ? parseFloat(loc.lat) : 0, [loc])
    const long = useMemo(() => loc ? parseFloat(loc.long) : 0, [loc])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false)
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    }, [tabIndex])

    const handleTabChange = ({ ind }) => {
        setLoading(true)
        setTabIndex(ind)
    }

    return (
        <BubbleContainer style={styles.bubbleContainer(isMobile)}>
            {!isMobile && <HeaderTitle text='Contact Info' />}
            <div style={styles.upperContent(isMobile)}>
                {tabs.length > 1 &&
                    <CustomTab
                        tabs={tabs}
                        tabsContainerStyle={{ backgroundColor: Colors.white, marginBottom: Space.large }}
                        onTabChange={handleTabChange} />}
                {Object.keys(contactInfo)?.map((key) => {
                    if (key === 'id' || key === 'uniId' || key === 'address') return
                    return <RowValuedLabel label={key} value={contactInfo[key]} isLoading={loading}/>
                })}
            </div>
            <div style={styles.buttonWrapper(isMobile)}>
                <Button color="accent" children="Visit Website"
                    style={styles.buttonStyle(isMobile)}
                    onClick={() => window.open(website)}
                    endIcon={<LinkIcon height={'20px'} width={'20px'} style={{ marginLeft: Space.medium }}/>}/>
            </div>
            {isMobile && loc &&
                 <div style={{ width: '100%', aspectRatio: 2, position: 'relative', marginTop: Space.large, left: 0 }}>
                    <GoogleMap lng={long} lat={lat} name={name} />
                </div>}
        </BubbleContainer>
    )
}

type Style = {
    buttonStyle: (isMobile?: boolean) => React.CSSProperties;
    buttonWrapper: (isMobile?: boolean) => React.CSSProperties;
    bubbleContainer: (isMobile?: boolean) => React.CSSProperties;
    upperContent: (isMobile?: boolean) => React.CSSProperties;
}
const styles: Style = {
    buttonWrapper: (isMobile) => (isMobile ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null),
    buttonStyle: (isMobile) => (isMobile ? { marginTop: Space.medium } : { marginTop: Space.largest, width: '100%' }),
    bubbleContainer: (isMobile) => (isMobile ? { padding: 0 } : { flex: '0.6 0 50%' }),
    upperContent: (isMobile) => (isMobile ? { padding: Space.large } : null)
}
export default ContactInfo;