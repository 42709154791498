import classNames from "classnames";
import { useOnScreen } from "hooks/useIsVisible";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useRef } from "react";
import { CustomBreakPoints } from "utils/constants";
import { StatVariant } from "utils/types";

interface Props {
    variant?: StatVariant;
    style?: React.CSSProperties;
    items: { title: string, value: number | string, desc?: string }[];
    circleProgressStyle?: {size?: string, thickness?: string}
}
const Statistics: React.FC<Props> = ({ variant, style, items, circleProgressStyle }) => {
    const nodeRef = useRef()
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const classes = classNames("stats", "shadow", {  "stats-vertical": isMobile })
    const {isIntersecting: isVisible, disconnect} = useOnScreen(nodeRef)

    useEffect(() => {
        if (isVisible) disconnect()
    }, [isVisible])

    if (variant === StatVariant.CirclularProgress) {
        return (
            /*@ts-ignore*/
            <div ref={nodeRef} className="radial-progress text-secondary" style={{ "--value": items[0].value, "--size": circleProgressStyle.size, "--thickness": circleProgressStyle.thickness }}>
                {items[0].value}%
            </div>
        )
    }
    return (
        <div className={classes} style={style} ref={nodeRef}>
            {items.map((item, index) => {
                if (item.value && typeof(item.value) === 'number' && isVisible) {
                    const obj = document.getElementById("value"+index);
                    animateValue(obj, 0, item.value, 1400);
                }
                const id = typeof(item.value) === "string" ? 'target' : "value" + index 
                return (
                    <div className="stat" key={index}>
                        <div className="stat-figure text-secondary">
                        </div>
                        <div className="stat-title">{item.title}</div>
                        <div className="stat-value" id={id}>{item.value ?? '-'}</div>
                        <div className="stat-desc">{item.desc}</div>
                    </div> 
                )
            })}
        </div>
    )
}
const animateValue = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
}

export default Statistics