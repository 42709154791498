import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Space } from "utils/constants";

interface Props {
    data: {label: string; value: string}[];
    onSelectItem?: (index: number) => void;
    icon?: () => JSX.Element
    isOneSided?: boolean;
}
const DropDown: React.FC<Props> = ({ data, onSelectItem, icon, isOneSided }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const onSelectHandler = useCallback((index) => {
        setSelectedIndex(index)
        onSelectItem(index)
    }, [onSelectItem])
    return (
        <div className="dropdown dropdown-hover">
            <Label tabIndex={0} className="btn m-1 no-animation" isOneSided={isOneSided}>
                <div style={{marginRight: icon ? Space.medium : 'initial'}}>
                    {data[selectedIndex].label}
                </div>
                {icon && icon()}
            </Label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                {data.map((item, index) => {
                    return (
                        <li key={item.value} onClick={() => onSelectHandler(index)}>
                            <a>
                                {item.label}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const Label = styled.label<{ isOneSided?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: lowercase;
    border-radius: ${props => props.isOneSided ? '0px' : '0.5rem'};
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
`
export default DropDown;