import React from "react";
import styled from "styled-components";
import { CustomBreakPoints } from "utils/constants";
import ig from "assets/svgs/social-media/ig.svg";
import linkedin from "assets//svgs/social-media/linkedin.svg";

export const SocialMediaLink = (props) => {
  return (
    <a href={props.website} rel="noreferrer" target="_blank">
      <Icon src={props.svg} alt={`${props.website.split(".")[0]}`} height={props.height}/>
    </a>
  );
};

export function SocialMediaLinks(props){
 return (
  <SocialMediaLinksContainer>
    <SocialMediaLink
      website={"instagram.com"}
      svg={ig}
      height={props.height}
      filter = {props.filter}
    />
    <SocialMediaLink
      website={"linkedin.com"}
      svg={linkedin}
      height={props.height}
      filter = {props.filter}
    />
  </SocialMediaLinksContainer>
 )
}
const Icon = styled.img`
  margin-right: 25px;
  &:hover {
    filter: brightness(0) saturate(100%) invert(75%) sepia(73%) saturate(0%)
      hue-rotate(163deg) brightness(98%) contrast(101%);
  }
  @media (max-width: ${CustomBreakPoints.tablet}) {
    margin-right: 20px;
  }
`;
const SocialMediaLinksContainer = styled.div`

`;
