import React, { useEffect, useState } from "react";
import UniveristyDetailsPage from "../UniversityDetailsPage";
import Loader from "components/Core/Loader";
import { Buffer } from "buffer";
import { JusoorAPI } from "API/api_client";
import { goTo } from "utils/helpers";
import { Routes } from "utils/constants";

const UniversityDetailsAdmin = ({ acronym, base64 }) => {
    const [id, setId] = useState()
    useEffect(() => {
        const s = Buffer.from(base64, "base64").toString()
        if (s === "jusoorio"+acronym) {
            JusoorAPI.get(`universities/acronym/${acronym}`)
                .then(res => {
                    if (res?.data?.status === 200) setId(res.data.id)
                })
                .catch(err => {
                    goTo(Routes.error, [], true)
                })
        } else {
            goTo(Routes.notfound, [],true)
        }
    
    }, [])

    if (!id) return (
        <Loader/>
    )
    return (
        <UniveristyDetailsPage id={id}/>
    )
}

export default UniversityDetailsAdmin;