import React, { useEffect } from "react";
import HomePage from "pages/HomePage"
import NavBar from "components/Navbar"
import Footer from "components/Footer"
import ContactUs from "./pages/ContactUs";
import Error404Page from "./pages/404"
import ErrorPage from "./pages/ErrorPage"
import ScholarshipsListing from "pages/ScholarshipsListing";
import UniversitiesListing from "pages/UniversitiesListing";
import UniversityDetails from "pages/UniversityDetailsPage";
import { Provider, useSelector } from "react-redux";
import { RootState, store } from 'redux/store'
import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { Route, Switch } from "wouter";
import "./index.css";
import AuthScreen from "pages/AuthScreen";
import { validateToken } from "API/webAPI";
import { setToken } from "redux/slices/userSlice";
import UniversityDetailsAdmin from "pages/Admin/UniveristyDetailsAdmin";
import ScholarshipsListingAdmin from "pages/Admin/ScholarshipListing";
import * as Sentry from "@sentry/react";

const PageLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div style={{ marginTop: '40px' }}>
        {children}
      </div>
      <Footer />
    </>
  )
}

Sentry.init({
  dsn: "https://24c6500af49af2ddaac36fa36a4b8640@o4505809764220928.ingest.sentry.io/4505809765466112",
  integrations: [new Sentry.Replay()],
  environment: process.env.environment ,
  tracesSampleRate: parseInt(process.env.SENTRY_TRACE_RATE),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_FB_ID,
  measurementId: process.env.REACT_APP_MESUARE_ID
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const storage = getStorage(app);

const InternalComponents = () => {
  const routeType = process.env.REACT_APP_ROUTE_TYPE

  return (
    <PageLayout>
    <Switch>
      {routeType === '3' ? renderPROD() : (routeType === '1' ? renderDEV() : renderQA())}
      <Route component={Error404Page}/>
    </Switch>
    </PageLayout>
  )
}

const renderQA = () => {
  const corporateUser = useSelector((state: RootState) => state.userReducer.corporateUser);
  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    if (userToken)
      validateToken(userToken)
        .then(res => {
          if (res) store.dispatch(setToken(userToken))
        })
  }, [])

  return (
    <>
      <Route path="/" component={!corporateUser ? AuthScreen : HomePage}/>
      <Route path="/home" component={!corporateUser ? AuthScreen : HomePage}/>
      {corporateUser ?
        <>
        <Route path="/scholarship-explore/:page?">
          {(params: any) => <ScholarshipsListing page={params.page} size={'15'}/>}
        </Route>
        <Route path="/university-explore/:page?">
          {(params: any) => <UniversitiesListing page={params.page} size={'15'}/>}
        </Route>
        <Route path="/university-details/:uniId">
          {(params: any) => <UniversityDetails id={params.uniId}/>}
        </Route>
        <Route path="/scholarship-details/:id">
          {(params: any) => <ScholarshipsListing page={'1'} size={'15'} id={params.id}/>}
        </Route>
        <Route path="/contact-us" component={ContactUs}/>
        <Route path="/sign-out" component={() => {
            store.dispatch(setToken(null))
            localStorage.removeItem('userToken')
            return <div/>
          }}/>
        </> : null}
      <Route path="/error" component={ErrorPage}/>
    </>
  )
}

const renderDEV = () => {
  return (
    <>
      <Route path="/" component={HomePage}/>
      <Route path="/home" component={HomePage}/>
      <Route path="/scholarship-explore/:page?">
        {(params: any) => <ScholarshipsListing page={params.page} size={'15'}/>}
      </Route>
      <Route path="/university-explore/:page?">
        {(params: any) => <UniversitiesListing page={params.page} size={'15'}/>}
      </Route>
      <Route path="/university-details/:uniId">
        {(params: any) => <UniversityDetails id={params.uniId}/>}
      </Route>
      <Route path="/scholarship-details/:id">
        {(params: any) => <ScholarshipsListing page={'1'} size={'15'} id={params.id}/>}
      </Route>
      <Route path="/contact-us" component={ContactUs}/>
      <Route path="/error" component={ErrorPage}/>
      <Route path="/sign-out" component={() => {
        store.dispatch(setToken(null))
        localStorage.removeItem('userToken')
        return <div/>
      }}/>
    </>
  )
}

const renderPROD = () => {
  return (
    <>
      <Route path="/" component={HomePage}/>
      <Route path="/home" component={HomePage}/>
      <Route path="/scholarship-explore/:page?">
        {(params: any) => <ScholarshipsListing page={params.page} size={'15'}/>}
      </Route>
      <Route path="/university-explore/:page?">
        {(params: any) => <UniversitiesListing page={params.page} size={'15'}/>}
      </Route>
      <Route path="/university-details/:uniId">
        {(params: any) => <UniversityDetails id={params.uniId}/>}
      </Route>
      <Route path="/scholarship-details/:id">
        {(params: any) => <ScholarshipsListing page={'1'} size={'15'} id={params.id}/>}
      </Route>
      <Route path="/:acronym/details/:base64">
        {(params: any) => <UniversityDetailsAdmin acronym={params.acronym} base64={params.base64}/>}
      </Route>
      <Route path="/:acronym/scholarships/:base64/:page?/:size?">
        {(params: any) => <ScholarshipsListingAdmin base64={params.base64} acronym={params.acronym} page={params.page ?? 1} size={params.size ?? 15}/>}
      </Route>
      <Route path="/contact-us" component={ContactUs}/>
      <Route path="/error" component={ErrorPage}/>
    </>
  )
}

function App() {
  return (
    <main style={{ userSelect: 'none', overflow: 'hidden' }}>
      <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
        <Provider store={store}>
          <InternalComponents/>
        </Provider>
      </Sentry.ErrorBoundary>
    </main>
  );
}

export default App;

