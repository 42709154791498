import { store } from "redux/store";
import { Colors, Routes, randomColors } from "./constants";
import { Address, RegistrationStatus } from "./types";

export const getTextColor = (bgColor: Colors) => {
    switch (bgColor) {
        case Colors.primary:
            return Colors.secondary;
        case Colors.secondary:
            return Colors.primary
        default:
            return Colors.primary
    }
}

export const transformDeadlineToStatus = (startDate: Date, endDate: Date) => {
    const currentDate = new Date()
    const daysOpenDiff = daysBetween(currentDate, startDate);
    const daysUntilClose = daysBetween(currentDate, endDate);
    if (daysOpenDiff < 0) {
        if (daysOpenDiff > -10 && daysUntilClose < 0) return RegistrationStatus.OPENING
        if (daysOpenDiff < -10 && daysUntilClose < 0) return RegistrationStatus.NOT_OPEN
    }else {
        if (daysUntilClose < -10) return RegistrationStatus.OPEN
        if (daysUntilClose <= 0) return RegistrationStatus.CLOSING;
        return RegistrationStatus.CLOSED
    }
}

const daysBetween = (startDate: Date, endDate: Date) => {
    //@ts-ignore
    return Math.round((startDate.getTime() - endDate.getTime()) / 86400000);
}

export const getCustomColor = (ind?: number) => {
    const index = ind % randomColors.length;

    return randomColors[index]
}

export const constructFilterQuery = (values: { [key: string]: any }) => {
    const items = Object.values(values);
    const params =  items.length > 0 ? items.map((v) => v).join('|') : items[0]
    return params
}

export const formatLocation = (address: Address[], index: number = 0) => {
    return `${address[index]?.city}, ${address[index]?.country}`
}

export const capitalizeFirstLetter = (str: string) => {
    return str[0].toUpperCase() + str.slice(1);
}

export const constructURL = (route: Routes, params: string[]) => {
    let customParams = ''
    params.forEach(p => {
        customParams += '/' + p
    })
    const url = process.env.REACT_APP_WEB_URL + `${route}${customParams}`
    return url;
}
export const goTo = (route: Routes, params: string[], isSelf?: boolean) => {
    const target = isSelf ? '_self' : null
    const url = constructURL(route, params);
    window.open(url, target);
}

export const extractUniImages = (acronym: string, include?: string[], exclude?: string[]) => {
    if (!acronym) return []
    const processedAcronym = acronym.toLowerCase().replace(" ", "")
    const universityRedux = store.getState().localImageReducer.university
    const images = universityRedux[processedAcronym]
    if (!images) return []

    let allImages: string[] = []
    if (include) {
        include.forEach(name => {
           allImages.push(images[name])
        })
    } else {
        allImages = ['']
        Object.keys(images).forEach(key => {
            const isExcluded = exclude?.filter(name => name === key) ?? []
            if (isExcluded.length > 0) return
        
            if (key !== 'priority') allImages.push(images[key])
            else allImages[0] = images[key]
        })
        if (allImages[0].length === 0) allImages = allImages.slice(1)
    }
    return allImages;
}

export const formatNumber = (number: number) => {
    if (!number) return ''
    let formattedNumber = number.toString();
    // Split the number into integer and decimal parts (if any)
    const parts = formattedNumber.split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1] || '';
    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Remove trailing zeros from the decimal part
    decimalPart = decimalPart.replace(/0+$/, '');
    // Build the formatted number
    formattedNumber = decimalPart.length > 0 ? `${integerPart}.${decimalPart}` : integerPart;
  
    return formattedNumber;
}
  
export const generateSequence = (count: number) => {
    return Array.from({ length: count }, (_, i) => i + 1)
}