import React from "react";
import styled from "styled-components";
import Text from "./Core/Text";
import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";
import Button from "./Core/Button";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ListingItems, Styles } from "utils/types";
import SearchEngine from "./SearchEngine";
interface Props {
    title: string;
    subtitle: string;
    illustration: (style?: React.CSSProperties) => JSX.Element;
    button: {text: string, onPress: () => void};
    isSecondary?: boolean;
    isReversed?: boolean;
    dynamicComponet?: 'BUTTON' | 'INPUT';
    type?: ListingItems
}
const IllustratedView: React.FC<Props> = ({ title, subtitle, illustration, button, isSecondary, isReversed, dynamicComponet, type }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    return (
        <Wrapper isSecondary={isSecondary}>
            <InnerWrapper isReversed={isReversed}>
                <TextualWrapper>
                    <Text fontSize={isMobile ? Size.larger : Size.xxLarge} fontWeight={700} color={isSecondary ? Colors.primary : Colors.secondary}
                        style={{textAlign: isMobile ? 'center' : 'left'}}>
                        {title}
                    </Text>
                    <Text fontSize={isMobile ? Size.medium : Size.large_medium} color={isSecondary ? Colors.primary : Colors.secondary}
                        style={{textAlign: isMobile ? 'center' : 'left', lineHeight: '24px'}}
                        margin={{top: Space.large, bottom: Space.largest}}>
                        {subtitle}
                    </Text>
                    {!isMobile && <DynamicComponent listingType={type} type={dynamicComponet} isSecondary={isSecondary} b={button}/>}
                </TextualWrapper>
                {illustration(styles.illustration(isMobile))}
                {isMobile && dynamicComponet !== 'INPUT'
                    && <Button style={{ marginTop: Space.larger }} children={button.text} color={isSecondary ? 'accent' : 'secondary'} className="btn-block" onClick={button.onPress}/>}
            </InnerWrapper>
        </Wrapper>
    )
}

const DynamicComponent = ({ type, isSecondary, b, listingType }) => {
    return type === 'INPUT' ? <InputComponent type={listingType}/> : <ButtonComponent isSecondary={isSecondary} button={b} />
}

const InputComponent = ({ type }) => {
    return (
        <div style={{paddingTop: '4em'}}>
            <SearchEngine type={type}/>
        </div>
    )
}

const ButtonComponent = ({ isSecondary, button }) => {
    return (
        <Button children={button.text} className="w-1/2"
            color={isSecondary ? 'accent' : 'secondary'} style={{marginTop: Space.larger}} onClick={button.onPress}/>
    )
}

const Wrapper = styled.div<{ isSecondary: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.isSecondary ? Colors.secondary : Colors.primary};
    padding: ${Space.larger};
`
const InnerWrapper = styled.div<{isReversed?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin-top: ${Space.medium};
    margin-bottom: ${Space.medium};
    @media (min-width: ${CustomBreakPoints.tablet}) {
        flex-direction: ${props => props.isReversed ? 'row-reverse' : 'row'};
        align-items: center;
        width: 80%;
        justify-content: space-between;
    }
`
const TextualWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        flex: 0.7;
    }
`

const styles: Styles = {
    illustration: (isMobile: boolean) => ({
        width: isMobile ? '90%' : '30%',
        height: '90%',
        marginTop: Space.medium,
        marginBottom: Space.medium,
        marginRight: isMobile ? 'initial' : '3em',
        minWidth: '240px'
    })
}
export default IllustratedView;