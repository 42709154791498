import Button from "components/Core/Button";
import Text from "components/Core/Text";
import TextInput from "components/Core/TextInput";
import { BubbleContainer } from "components/StyledComponents/BubbleContainer";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import { ReactComponent as Lock } from "assets/svgs/icons/lock.svg"
import { authenticate } from "API/webAPI";
import { store } from "redux/store";
import { setToken } from "redux/slices/userSlice";
import { useMediaQuery } from "hooks/useMediaQuery";
import { goTo } from "utils/helpers";

const AuthScreen = () => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleInputChange = useCallback((type: 'username' | 'password') => {
        setError(null)
        const input = document.getElementById(type) as HTMLInputElement | null;
        if (type === "username") setUsername(input.value)
        else setPassword(input.value)
      }, []) 
    const handleInputBlur = useCallback((input?: string) => {
        if (!input || input.length === 0) setError('All inputs are required')
      }, []) 
    
    const handleSubmit = useCallback(async () => {
        setLoading(true)
        const response  = await authenticate(username, password)
        if (response?.access) {
            store.dispatch(setToken(response.access))
            localStorage.setItem('userToken', response.access);
            goTo(Routes.home, [], true)
        } else {
            setError("Wrong credentials!")
            setLoading(false)
        }
    }, [username, password])

    return (
       <Wrapper>
        <div style={{ height: '70%', width: isMobile ? '100%' : '80%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
         <Container>
            <LeftSection isMobile={isMobile}>
                <img src={require('../assets/images/logo/logo.png')} style={{ width: '100px', height: '80px' }}/>
                <Text fontSize={Size.xLarge} fontWeight={600}>
                    Jusoor.io
                </Text>
                {errorMsg &&
                        <Text fontWeight={200} color="red" fontSize={Size.medium}>
                            {errorMsg}
                        </Text>}
                <div style={{ width: '55%', display: 'flex', flexDirection: 'column', height: '100px' }}>
                    <div style={{ marginBottom: Space.medium, marginTop: Space.large, width: '100%', display: 'flex' }}>
                        <TextInput id="username" placeholder="username" onInput={() => handleInputChange('username')}
                            onBlur={() => handleInputBlur(username)}/>
                    </div>
                    <div style={{ marginBottom: Space.large, width: '100%', display: 'flex' }}>
                        <TextInput id="password" placeholder="password" type="password" onInput={() => handleInputChange('password')}
                            onBlur={() => handleInputBlur(password)}/>
                    </div>
                    <Button children="Sign in" wide={isMobile} onClick={handleSubmit} disabled={errorMsg || username.length === 0 || password.length === 0} loading={loading}/>
                </div>

            </LeftSection>
            
            <RightSection>
                <Lock height={150} />
                <Text fontSize={Size.xLarge} margin={{ top: Space.medium }} fontWeight={500} color="white">
                    You have reached a restriced Area
                </Text>
                <Text fontSize={Size.large_medium} margin={{ top: Space.medium }} color="white">
                   Please sign in using your corporate email to continue.
                </Text>
            </RightSection>
         </Container>
         </div>
       </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${Space.largest};
`
const Container = styled(BubbleContainer)`
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 0;
    align-items: center;
    justify-content: space-between;
`
const LeftSection = styled.div<{ isMobile?: boolean }>`
    padding: ${Space.medium};
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const RightSection = styled.div`
    padding: ${Space.medium};
    background-color: ${Colors.primary};
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: ${CustomBreakPoints.phone}) {
        visibility: hidden;
    }
`

export default AuthScreen;