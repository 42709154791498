import React from "react";
import { Link as L, useRoute } from "wouter";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";
import { logNavigateEvent } from "tracking/google";

const Link = (props) => {
    const [isActive] = useRoute(props.to+'/:page?');
    return (
        <L {...props} onClick={() => logNavigateEvent('', props.to, 'nav_link')}>
            <Anchor className={isActive ? "active" : ""}>{props.children}</Anchor>
        </L>
    )
}
const Anchor = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: ${Colors.black};
  font-size: ${Size.large_medium};
  padding-left: ${Space.large};
  padding-right: ${Space.large};
  font-size: ${Size.large_medium};
  &.active {
    color: ${Colors.purple};
    text-decoration-thickness: 0.2em;
    text-decoration-color: ${Colors.purple}
  }
  &:hover {
    cursor: pointer;
    color: ${Colors.purple};
    text-decoration: underline;
    text-decoration-thickness: 0.2em;
    text-decoration-color: ${Colors.purple};
  }
  @media (max-width: ${CustomBreakPoints.desktop}) {
    &.active {
      background-color: ${Colors.purple};
      color: ${Colors.white};
      border-radius: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-left: 6px
    }
  }
`;
export default Link;