import React from "react";
import { University } from "types/University";

export type ColorsTemplate = {
    primary: string;
    secondary: string;
}

export type SwiperItemContent = {
    image: string;
    name: string;
}

export interface SwiperItemProps {
    itemStyle?: React.CSSProperties;
    footerStyle?: React.CSSProperties;
    item: University;
    onClickHandler?: () => void;
}

export enum ButtonType {
    outline = "OUTLINE",
    filled = "FILLED",
    text = "TEXT"
}

export enum StatVariant {
    CirclularProgress = "circlularProgress"
}

export enum HeroVariants {
    HomeVariant1 = "home_design_1",
    HomeVariant2 = "home_design_2",
    UniversityVariant = "university_design",
    ScholarshipVariant = "scholarship_design"
}

export enum HeroSectionVariants {
    HomeVariant1 = "home_variant_1",
    ScholarshipVariant1 = "scholarship_variant_1",
    UniversityVariant1 = "univeristy_variant_1",
}

export enum ListingItems  {
    Scholarship = "scholarship",
    University = "university"
}

export enum RegistrationStatus {
    CLOSING = "closing-soon",
    OPENING = "opening-soon",
    OPEN = "opened",
    CLOSED = "closed",
    NOT_OPEN = "not-open",
    UNKNOWN = "unknown"
}

export type Ranking = {
    rankId: number;
    uniId: number;
    region: string;
    year: string;
    rank: string;
    metric: string;
}

export type Address = {
    country: string;
    destrict: string;
    city: string;
    street: string;
    longitude: string;
    latitude: string;
}

export type Requirement = {
    id: number;
    name: string;
    value: string;
}
export type Application = {
    appId: number;
    term?: string;
    startDate?: string;
    deadline?: string;
    fees?: string;
    currency?: string;
    link?: string; 
    level?: string;
    requirements?: Requirement[]
}

export type ContactInfo = {
    FAX?: string,
    PObox?: string,
    email?: string
    number1?: string,
    number2?: string,
    address?: Address,
    id: number
}

export type Stats = {
    establishmentYear: number;
    studentsNb: number;
    internationalStudentsNb: number;
    acceptanceRate?: number;
    uniId: number;
    id: number
}

export type Styles = {
    [styleName: string]: (...args: any) => React.CSSProperties | React.CSSProperties;
}

export type Faculty = {
    [faculty: string] : PartialMajor
}
export type PartialMajor = {
    id: number;
    credits?: number,
    duration?: number,
    link?: string,
    level: "UnderGraduate" | "All" | "Graduate",
    faculty: string,
    name: string
}
export type Major = PartialMajor & {
    core: {
        majorId: number;
        description: string;
        name: string;
        highlights: string;
    }
}