import { findUniversitiesByMajor, getMajor } from "API/webAPI";
import Loader from "components/Core/Loader";
import ScrollView from "components/Core/ScrollView";
import Text from "components/Core/Text";
import HeaderTitle from "components/University/UniversityDetails/HeaderTitle";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { Colors, CustomBreakPoints, Routes, Space } from "utils/constants";
import { extractUniImages, goTo } from "utils/helpers";
import { ReactComponent as Disclaimer} from "assets/svgs/icons/disclaimer.svg"
import { logNavigateEvent } from "tracking/google";

const reducer = (state, action) => {
    switch(action.type) {
        case 'fetch-start':
            return { ...state, loading: true, error: null, major: null, similarity: [], similarityLoader: true, similarityError: null }
        case 'fetch-done':
            return { ...state, loading: false, error: null, major: action.data }
        case 'fetch-failed':
            return { ...state, loading: false, error: action.error, major: null }
        case 'fetch-similarity-done':
                return { ...state, similarityLoader: false, similarity: action.data }
        case 'fetch-similarity-failed':
            return { ...state, similarityError: action.error }
    }
}
const MajorDetails: React.FC<{id: number, uniId: number}> = ({ id, uniId }) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        similarity: [],
        similarityError: null,
        error: null,
        major: null
      });
    const { major, loading, similarity, similarityError } = state;
   
    const onMajorIdChange= useCallback(async (id: number) => {
        try {
            dispatch({ type: 'fetch-start' })
            const results = await getMajor(id)
            findUniversitiesByMajor(results.core.majorId, uniId)
                .then((res) => {
                    dispatch({ type: 'fetch-similarity-done', data: res })
                })
                .catch((err) => {
                    console.log('Failed to get universities')
                    dispatch({ type: 'fetch-similarity-failed', error: err }) 
                })
            dispatch({ type: 'fetch-done', data: results })
        } catch(err) {
            dispatch({ type: 'fetch-failed', error: err })
        }
    }, [])

    useEffect(() => {
        let unmount = false
        if (unmount || !id) return
        onMajorIdChange(id)
        return () => {
            unmount = true
        }
    }, [id])

    if (loading || !major) return <Loader/>
    const regex = /<li>(.*?)<\/li>/g;
  // Extract list items using the regex
  const matches = major.core?.highlights.match(regex);
    return (
        <>
        {/* <div className="alert alert-warning" style={{ display: 'flex',  height: '60px', marginTop: Space.large, marginBottom: Space.large, overflow: 'scroll' }}>
            <Disclaimer height={20} width={20}/>
            <span>Remember, specific programs may vary among universities, so it's essential to contact the university for more info about this program.</span>
        </div> */}
        <HeaderTitle text={major.core?.name}/>
        <Text margin={{bottom: Space.largest}}>
            {major.core?.description}
        </Text>
        <HeaderTitle text="Highlights"/>
            <ul style={{ listStyleType: 'disc', marginLeft: Space.large }}>
                {matches.map((item, index) => {
                // Remove <li> tags from each item
                const listItem = item.replace(/<\/?li>/g, '');
                return <li key={index}>{listItem}</li>;
                 })}
            </ul>
        {similarityError || similarity?.length === 0 ? null
            : <>
                <HeaderTitle text="Other Unis that Offer this Major" style={{ marginTop: Space.large }}/>
                <SimilarityList items={similarity} page={`/major-details/${major.core?.name?.replaceAll(' ', '-')}`}/>
              </> }
        </>
    )
}

export const SimilarityList = ({ items, page }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const uniImageStore = useSelector((state: RootState) => state.localImageReducer.university)
    return (
        <ScrollView slidesPerView={isMobile ? 1.8 : 4.5} spaceBetween={15} isFreeMode>
        {items.map(data => {
            return (
                <UniversityDiv key={data.id} isMobile={isMobile} onClick={() => {
                    const acronym = data.acronym?.toLocaleLowerCase()
                    goTo(Routes.universityDetails, [acronym], false)
                    logNavigateEvent(page, Routes.universityDetails + `/${acronym}`, 'suggested-uni-card')
                }}>
                    <Image src={extractUniImages(data.acronym,['thumbnail'])?.[0]}/>
                    <Text fontWeight={600} style={{ padding: Space.medium}}>
                        {data.name}
                    </Text>
                </UniversityDiv>
            )
        })}
    </ScrollView>
    )
}
const UniversityDiv = styled(SwiperSlide)<{isMobile: boolean}>`
    background-color: ${Colors.blueish};
    border-radius: 8px;
    aspect-ratio: ${props => props.isMobile ? 0.8 : 1};
    overflow: hidden;
`

const Image = styled.img`
    height: 70%;
    width: 100%;
    flex: 0.8;
    border-radius: 8px;
    object-fit: cover;
`
export default MajorDetails;