import { store } from "redux/store"
import { University, UniversityDetails, UniversityListingResponse } from "types/University";
import { handleApiErrors, JusoorAPI } from "./api_client";
import { FirebaseStorage, getDownloadURL, listAll, ref } from "firebase/storage";
import { setImage } from "redux/slices/localImageStore";
import { storage } from "App";
import { Faculty, Major } from "utils/types";
import { ScholarshipListItem, ScholarshipListingResponse } from "types/Scholarship";
import { logErrorEvent } from "tracking/google";

export const getUniversityList = async (pageSize: string, page: string, filters?: any): Promise<UniversityListingResponse> => {
    try {
        const p = { 'page': page, 'page_size': pageSize }
        if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach((key) => p[key] = filters[key].join('|'))
        }
        const response = await JusoorAPI.get('universities/', p)
        if (response.data.status === 200) {
            const data = response.data
            const next = data.hasNext === true ? parseInt(page) + 1 : null
            const prev = data.hasPrev === true ? parseInt(page) - 1 : null
            const res = { count: data.count, results: data.results, next, prev}
            data.results.forEach((uni: University) => {
                if (!uni.acronym) return
                const acronym = uni.acronym.toLowerCase().replace(" ", "")
                const type = 'university'
                const image = 'thumbnail'
                getImageURL({type, folderName: acronym, imageName: image}, storage)
                    .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
                    .catch(err => console.log('Firebase get url failed'))
            })
            return res
        }
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: 'universities/' })
    }
    return {
        count: 0,
        results: []
    }
}

export const getUniversityDetails = async (uniId: string) => {
    try {
        const response = await JusoorAPI.get(`universities/details/${uniId}`)
        if (response.data) {
            const data: UniversityDetails = response.data
            const acronym = data.acronym?.toLowerCase().replace(" ", "")
            listAll(ref(storage, `/university/${acronym}`))
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        const image = itemRef.name?.replace(".webp", "")
                        const type = 'university'
                        getImageURL({type, folderName: acronym, imageName: image}, storage)
                            .then(url => store.dispatch(setImage({ type, folder: acronym, name: image, url })))
                            .catch(err => console.log('Firebase get url failed'))
                    });
                }).catch((error) => {
                    logErrorEvent(error, { endpoint: `universities/details/${uniId}` })
                });
            return response.data
        }
    } catch (err) {
        handleApiErrors(err)
    }
}

export const getAutoCompleteSearch = async (type: 'university' | 'scholarship', text?: string) => {
    const endpoint = type === 'university' ? 'universities' : 'scholarships'
    try {
        const params = text ? { 'name': text } : null
        const response = await JusoorAPI.get(`${endpoint}/search/`, params)
        if (response.status === 200) return response.data.results
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: `${endpoint}/search/` })
        throw err
    }
}

export const getImageURL = async (config: {type: string, folderName: string, imageName: string, extension?: string }, storage: FirebaseStorage) => {
    try {
        const {type, folderName, imageName, extension} = config;
        const founded = store.getState().localImageReducer?.[type]?.[folderName]?.[imageName]
        if (founded) return founded
        const ext = extension ?? 'webp'
        const path = `/${type}/${folderName.toLowerCase()}/${imageName}.${ext}`.replace(" ", "")
        const url = await getDownloadURL(ref(storage, path));
        return url;
    } catch(error) {
        logErrorEvent(error, { name: 'image_download' })
    }
    return `https://firebasestorage.googleapis.com/v0/b/jusoor-456e3.appspot.com/o/university%2Fdefault.webp?alt=media&token=47c42b6f-af51-49bb-a8cd-7c03da9140a8`
}

export const getFeaturedUniversities = async () => {
    try {
        const response = await JusoorAPI.get('universities/featured/')
        if (response.status === 200) {
            const data = response.data
            data?.forEach((uni: University) => {
                if (!uni.acronym) return
                const acronym = uni.acronym.toLowerCase().replace(" ", "")
                const type = 'university'
                const image = 'thumbnail'
                getImageURL({type, folderName: acronym, imageName: image}, storage)
                    .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
                    .catch(err => console.log('Firebase get url failed'))
            })
            return data
        }
    } catch (error) {
        logErrorEvent(error, { endpoint: 'universities/featured/' })
        throw error;
    }
}

export const getUniversityFaculties = async (id: number) => {
    try {
        const response = await JusoorAPI.get('majors/', { 'uni-id': id })
        if (response.status === 200) {
            const faculties: Faculty = response.data.majors
            // data?.forEach((uni: University) => {
            //     if (!uni.acronym) return
            //     const acronym = uni.acronym.toLowerCase().replace(" ", "")
            //     const type = 'university'
            //     const image = 'thumbnail'
            //     getImageURL({type, folderName: acronym, imageName: image}, storage)
            //         .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
            //         .catch(err => console.log('Firebase get url failed'))
            // })
            return faculties
        }
    } catch (error) {
        logErrorEvent(error, { endpoint: 'majors/' })
        throw error;
    }
}

export const getMajor = async (id: number) => {
    try {
        const response = await JusoorAPI.get(`majors/details/${id}`)
        if (response.status === 200) {
            const major: Major = response.data
            return major
        }
    } catch (error) {
        logErrorEvent(error, { endpoint: `majors/details/${id}` })
        throw error;
    }
}

export const getScholarshipList = async (pageSize: string, page: string, filters?: object): Promise<ScholarshipListingResponse> => {
    try {
        const filterReducer = store.getState().filterReducer
        const p = { 'page': page, 'page_size': pageSize, ...filters }
        if (filterReducer.filtersActive) {
            const types = filterReducer.Type
            let filterTypes = ""
            Object.keys(types).forEach((key, index) => {
                if (types[key].value) {
                    filterTypes += types[key].query
                    if (Object.keys(types).length > 1 && index < Object.keys(types).length - 1) filterTypes += "|"
                }
            })
            if (filterTypes.length > 0) p["types"] = filterTypes

            const universities = filterReducer.Universities.value
            let filterUniversities = ""
            universities.forEach((item, index) => {
                filterUniversities += item
                if (universities.length > 1 && index < universities.length - 1) filterUniversities += "|"
            })
            if (filterUniversities.length > 0) p["ids"] = filterUniversities

            const amount = filterReducer.Amount.value
            if (amount) p["up-to"] = amount
           
        }
        const response = await JusoorAPI.get('scholarships/', p)
        if (response.data.status === 200) {
            const data = response.data
            const next = data.hasNext === true ? parseInt(page) + 1 : null
            const prev = data.hasPrev === true ? parseInt(page) - 1 : null
            const res = { count: data.count, results: data.results, next, prev}
            data.results.forEach((item: ScholarshipListItem) => {
                if (!item.university) return
                const acronym = item.university.toLowerCase().replace(" ", "")
                const type = 'university'
                const image = 'logo/' + acronym.toUpperCase()
                getImageURL({type, folderName: acronym, imageName: image}, storage)
                    .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
                    .catch(err => console.log('Firebase get url failed'))
            })
            return res
        }
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: 'scholarships/' })
    }
    return {
        count: 0,
        results: []
    }
}

export const getScholarshipDetails = async (id: number) => {
    try {
        const response = await JusoorAPI.get(`scholarships/details/${id}`)
        if (response.data) {
            if (response.data.uniId) {
                const { uniAcronym } = response.data;
                const acronym = uniAcronym.toLowerCase().replace(" ", "")
                const type = 'university'
                const image = 'thumbnail'
                getImageURL({type, folderName: acronym, imageName: image}, storage)
                    .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
                    .catch(err => console.log('Firebase get url failed', err))
            }
            
            return response.data
        }
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: `scholarships/details/${id}` })
    }
}

export const findUniversitiesByMajor = async (id: number, uniId) => {
    try {
        const response = await JusoorAPI.get('/majors/universities-by-major/', { 'major-id': id, exclude: uniId })
        if (response.status === 200) {
            const data = response.data
            data.results.forEach((res) => {
                if (!res.acronym) return
                const acronym = res.acronym.toLowerCase().replace(" ", "")
                const type = 'university'
                const image = 'thumbnail'
                getImageURL({type, folderName: acronym, imageName: image}, storage)
                    .then(url => store.dispatch(setImage({type, folder: acronym, name: image, url })))
                    .catch(err => console.log('Firebase get url failed', err))
            })
            return data.results
        }
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: '/majors/universities-by-major/' })
    }
}

export const sendFeedback = async (name: string, email: string, message: string, val: string) => {
    try {
        const body = { name, email, message, feedbackType: val }
        const response = await JusoorAPI.post('contact-us/submit', body)
        return response.status === 200
    } catch (err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: 'contact-us/submit' })
    }
    return false
}

export const authenticate = async (username: string, password: string) => {
    try {
        const body = { username, password }
        const response = await JusoorAPI.post('user/auth/', body)
        return response.data
    } catch(err) {
        //handleApiErrors(err)
    }
    return false
}

export const validateToken = async (token: string) => {
    try {
        const body = { token }
        const response = await JusoorAPI.post('user/token/validate/', body)
        return response.status === 200
    } catch(err) {
        console.log(err)
        //handleApiErrors(err)
    }
    return false
}
export const subscribeEmail = async (email: string) => {
    try {
        const response = await JusoorAPI.post('user/subscribe/', { email })
        return response.data
    } catch(err) {
        logErrorEvent(err, { endpoint: '/user/subscribe' })
    }
    return null
}

export const getStats = async () => {
    try {
        const response = await JusoorAPI.get('/stat')
        if (response.data?.status === 200)
            return response.data.data
    } catch(err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: '/stat' })
    }
}

export const queryAllUniversities = async () => {
    try {
        const response = await JusoorAPI.get('universities/query-all')
        if (response.data?.status === 200) return response.data.results
    } catch(err) {
        handleApiErrors(err)
        logErrorEvent(err, { endpoint: '/query-all' })
    }
}