import ContactNote from "components/Core/ContactNote";
import React, { useState } from "react";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import Location from "assets/svgs/contact/location.svg";
import Phone from "assets/svgs/contact/phone.svg";
import Email from "assets/svgs/contact/email.svg";
import ContactForm from "components/ContactForm";
import HeaderTitle from "components/University/UniversityDetails/HeaderTitle";
import { BubbleContainer, bubbleContainerStyle } from "components/StyledComponents/BubbleContainer";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import { useMediaQuery } from "hooks/useMediaQuery";
import Text from "components/Core/Text";
import { Button } from "react-daisyui";
import { goTo } from "utils/helpers";

const ContactUs = () => {
  const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
  const [isSuccessful, setStatus] = useState(false)
  return (
      <OuterContainer>
        <InnerContainer style={isMobile ? bubbleContainerStyle.mobile : {}}>
          {isSuccessful ?
            <SuccessBody>
              <img src={require("../assets/images/submitted.jpg")} style={{ width: isMobile ? '100%' : '50%' }}/>
              <Text fontSize={Size.xLarge} fontWeight={600}>
                Submitted Successfully
              </Text>
              <Text fontSize={Size.large_medium} margin={{ bottom: Space.larger }}>
                We are gonna get back to you ASAP
              </Text>
              <Button children="Go Back Home" onClick={() => goTo(Routes.home, [], true)}/>
            </SuccessBody>
            : <>
              <HeaderTitle text="Contact Us"/>
              <SubInnerContainer>
                <BubbleContainer>
                  <FlexDirection.Row style={{ justifyContent: 'space-between', flexDirection: isMobile ? 'row' : 'column' }} className="divide-x">
                    <ContactNote
                      textTitle="Address"
                      textNote="Hamra, Lebanon"
                      imgSrc={Location}
                    />
                    <ContactNote
                      textTitle="Phone"
                      textNote="+96170301032"
                      imgSrc={Phone}
                    />
                    <ContactNote
                      textTitle="Email"
                      textNote="hello@jusoor.io"
                      imgSrc={Email}
                    />
                  </FlexDirection.Row>
                </BubbleContainer>
                <DividerContainer>
                  <Divider/>
                </DividerContainer>
                <ContactForm onSuccess={() => setStatus(true)}/>
              </SubInnerContainer>
              </>}
        </InnerContainer>
      </OuterContainer>
  );
};

export default ContactUs;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  width: 100%;
  padding: ${Space.large};
`;
const InnerContainer = styled(BubbleContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-self: center;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    background-color: ${Colors.blueish};
    width: 80%;
    margin-top: ${Space.largest};
    margin-bottom: ${Space.largest};
    padding: ${Space.largest};
  }
`;
const SubInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${Size.large_medium};
  width: 100%;
  @media (min-width: ${CustomBreakPoints.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;
const DividerContainer = styled.div`
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  margin-left: 5vw;
  margin-right: 5vw;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;
const Divider = styled.div`
  height: 40vh;
  border-right: 6px solid #e0e0e0;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    display: none;
  }
`;
const SuccessBody = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  @media (max-width: ${CustomBreakPoints.tablet}) {
    height: 65vh;
  }
`
