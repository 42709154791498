import React, { useCallback, useEffect, useState } from 'react';
import { HeroSectionVariants, ListingItems } from 'utils/types';
import { ReactComponent as Illustration } from 'assets/svgs/illustrations/scholarship.svg'
import 'react-modern-drawer/dist/index.css'
import ItemsListing from './ItemsListing';
import { ScholarshipListingResponse } from 'types/Scholarship';
import { getScholarshipList } from 'API/webAPI';
import ScholarshipDetails from 'components/Scholarship/ScholarshipDetails';
import Modal from 'components/Core/Modal';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { CustomBreakPoints } from 'utils/constants';
interface Props {
  page: string;
  size: string;
  id?: number;
}
const ScholarshipsListing: React.FC<Props> = ({ page, size, id }) => {
    const [data, setData] = useState<ScholarshipListingResponse | undefined>();
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState(id)

    const fetchScholarships = useCallback((p?: string) => {
      window.scrollTo(0, 0)
      setLoading(true)
      getScholarshipList(size, p ?? page)
          .then(res => {
              setLoading(false)
              setData(res)
          })
          .catch(err => {
              setLoading(false)
              console.log('err', err)
          })
    }, [size, page])

    useEffect(() => {
       fetchScholarships()
    }, [size, page])
  
    const scholarships = data?.results
    const pagination = { next: data?.next, prev: data?.prev, count: data?.count, params: '', current: parseInt(page ?? '1'), size: parseInt(size)}

    return (
      <>
        <ItemsListing
            paginationInfo={pagination}
            type={ListingItems.Scholarship}
            loading={loading}
            items={scholarships}
            id={id}
            extensions={{ setSelectedId }}
            heroContent={{
              title: "Scholarships",
              description:
                "Discover all about scholarships and grants available for higher education. We provide an extensive list of scholarship opportunities from a variety of sources that can help you pay for college.",
              variant: HeroSectionVariants.ScholarshipVariant1,
              illustration: (styles) => <Illustration style={styles} />
            }}
            refresh={() => fetchScholarships('1')}
            />
          <Modal id="scholarship-details-modal" width={isMobile ? "100%" : "60%"} isOpen={!!selectedId} onClose={() => setSelectedId(null)}>
            <ScholarshipDetails id={selectedId} />
          </Modal>
        </>
    )
}

export default ScholarshipsListing;
