import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useMemo } from 'react';
import { Colors, CustomBreakPoints, Size, Space } from 'utils/constants';
import { University } from 'types/University';
import ScholarshipItem from 'components/Scholarship/ScholarshipItem';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import Pagination from 'components/Pagination';
import { generateSequence } from 'utils/helpers';
import { ScholarshipListItem } from 'types/Scholarship';

interface Props {
    items: ScholarshipListItem[] | University[];
    paginationInfo: { count: number, next?: number, prev?: number, params?: string, current: number, size: number };
    loading?: boolean;
    id?: number;
    extensions?: { setSelectedId?: any };
    acronym: string;
    base64: string;
};
const ItemsListingCopy: React.FC<Props> = ({ items, extensions, paginationInfo, loading, acronym, base64 }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const data = useMemo(() => loading ? generateSequence(paginationInfo.size) : items, [loading])
    return (
        <Wrapper>
            <div style={styles.contentWrapper(isMobile)}>
                <List>
                    {data?.map((item, index) => <ScholarshipItem isLoading={loading} key={index} scholarship={item} onPress={extensions.setSelectedId}/>)}
                </List>  
            </div>
            <Pagination pageSize={paginationInfo.size} currentPage={paginationInfo.current?? 0} totalPages={Math.ceil(paginationInfo.count / paginationInfo.size)} route={`/${acronym}/scholarships/${base64}`} params={paginationInfo.params}/>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    background-color: ${Colors.white};
    width: 100%;
    margin: auto;
    margin-top: 3.5em;
    @media (max-width: ${CustomBreakPoints.phone}) {
        width: 100%;
    }
`
const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: ${Space.larger};
    flex: 1;
`

type Style = {
    searchBar: React.CSSProperties;
    contentWrapper: (isMobile: boolean) => React.CSSProperties;
    filterContainer: React.CSSProperties;
    filterButton: React.CSSProperties;
    drawer: React.CSSProperties;
    desktopDrawer: React.CSSProperties;
    drawerHeader: React.CSSProperties;
    filterHeader: React.CSSProperties;
    list: React.CSSProperties;
}
const styles: Style = {
    searchBar: {
        width: '70%',
        marginLeft: Space.large
    },
    contentWrapper: (isMobile) => ({
        margin: Space.medium,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100%'
    }),
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: Size.thin_medium,
        fontWeight: 'bold',
        width: '100%',
        marginBottom: Space.large
    },
    filterButton: {
        display: 'flex',
        position: 'fixed',
        right: 20,
        top: window.innerHeight * 0.9,
        zIndex: 1000,
        backgroundColor: Colors.purple,
        width: '50px',
        height: '50px',
        borderRadius: '35px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    filterHeader: {
        display: 'flex',
        flex: 0.4,
        height: '100%',
        flexDirection: 'column',
        padding: Space.larger,
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: Colors.offWhite,
        borderStyle: 'solid'
    },
    drawer: {
        height: '85%',
        padding: Space.large,
        paddingTop: '0px',
        boxSizing: 'border-box',
        overflowY: 'scroll',
        zIndex: 1000,
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
    },
    desktopDrawer: {
        backgroundColor: 'transparent',
        height: '85%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none'
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '9px',
        position: 'sticky',
        width: '100%',
        padding: Space.medium,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.white
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center' 
    }
}
export default ItemsListingCopy;