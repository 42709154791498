import ScrollView from "components/Core/ScrollView";
import { BubbleContainer } from "components/StyledComponents/BubbleContainer";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { Colors, Size, Space } from "utils/constants";
import styled from "styled-components";
import { ReactComponent as ScholarshipIcon } from "assets/svgs/icons/scholarship-icon.svg";
import { ReactComponent as CertificateIcon } from "assets/svgs/icons/certificate-icon.svg";
import { ReactComponent as CommunityIcom } from "assets/svgs/icons/community-icon.svg";
import Text from "components/Core/Text";
import { formatNumber } from "utils/helpers";
import { Stats as Stat } from "utils/types";

interface Props {
    isMobile?: boolean;
    stats: Stat

}
const Stats: React.FC<Props> = ({ isMobile, stats }) => {
    const { establishmentYear: year, studentsNb, internationalStudentsNb, acceptanceRate} = stats
    return (
        <BubbleContainer style={styles.container(isMobile)}>
            <ScrollView showPagination>
                <SwiperSlide>
                    <FlexDirection.Row>
                        <Item icon={<ScholarshipIcon height="50%" width="50%" />} label={`Founded in ${year}`} shouldRender={!!year}/>
                        <Item icon={<CertificateIcon height="50%" width="50%" />} label={`${acceptanceRate}% Acceptance Rate`} shouldRender={!!acceptanceRate}/>
                        <Item icon={<CommunityIcom height="50%" width="50%" />}  label={`${formatNumber(studentsNb)} Students`} shouldRender={!!studentsNb}/>
                        <Item
                            icon={<CommunityIcom height="50%" width="50%" />}
                            label={`${formatNumber(internationalStudentsNb)} International Students`}
                            shouldRender={!isMobile && !!internationalStudentsNb}/>
                    </FlexDirection.Row>
                </SwiperSlide>
            </ScrollView>
        </BubbleContainer>
    )
}

const Item: React.FC<{ icon: JSX.Element, label: string, shouldRender?: boolean }> = ({ icon, label, shouldRender }) => {
    if (shouldRender) {
        return (
            <Div>
                {icon}
                <Text fontSize={Size.thin_medium} semibold margin={{ top: Space.small }} children={label}/>
            </Div>
        )
    }
    return null
}
const Div = styled.div`
    border-radius: 12px;
    border-color: grey;
    border-width: 1px;
    height: 90px;
    display: flex;
    flex: 1;
    border-style: solid;
    margin-right: ${Space.medium};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${Space.medium};
    box-sizing: border-box;
    text-align: center;
`

type Style = {
    container: (isMobile: boolean) =>  React.CSSProperties;
}
const styles: Style = {
    container: (isMobile) => (
        isMobile ?
            {
                backgroundColor: Colors.white,
                padding: 0,
                paddingTop: Space.large
            }
            : {
                flex: 0.26,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: Space.large
            }
    )
}
export default Stats;