import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Size, Space, defaultImage } from "utils/constants";
import { UniversityDetails } from "types/University";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { extractUniImages, formatLocation } from "utils/helpers";
import Modal from "components/Core/Modal";
import HeaderTitle from "components/University/UniversityDetails/HeaderTitle";
import { BubbleContainer } from "components/StyledComponents/BubbleContainer";
import AboutUs from "components/University/UniversityDetails/AboutUs";
import Stats from "components/University/UniversityDetails/Stats";
import ImportantDates from "components/University/UniversityDetails/ImportantDates";
import ContactInfo from "components/University/UniversityDetails/ContactInfo";
import RankingCarousel from "components/University/UniversityDetails/RandkingCarousel";
import Requirements from "components/University/UniversityDetails/Requirements";
import Faculties from "components/University/UniversityDetails/Faculties";
import MajorDetails from "components/Major/MajorDetails";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import { getUniversityDetails } from "API/webAPI";
import Loader from "components/Core/Loader";

const UniveristyDetailsPage: React.FC<{ id: string }> = ({ id }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const [university, setUniversity] = useState<UniversityDetails | undefined>();
    const [loading, setLoading] = useState(false)
    const [selectedMajorId, setSelectedMajor] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        setUniversity(null)
        getUniversityDetails(id)
            .then(res => {
                setLoading(false)
                setUniversity(res)
            })
            .catch(err => {
                setLoading(false)
                console.log('err', err)
            })
    }, [id])

    const { contactDetails, applications, name, acronym, description, website, stats, rankings } = university ?? {}
    const universityAddress = contactDetails?.length > 0 ? contactDetails[0]?.address : {longitude: null, latitude: null}
    const applicationTerms = applications?.map(app => app.term)
    //@ts-ignore
    const location = universityAddress?.longitude && universityAddress?.latitude ? formatLocation([universityAddress]) : null
    const campuses = contactDetails?.map(data => data.address.city)
    const limit = isMobile ? 3 : 5;
    const requirements = useMemo(() => {
        const val = {}
        applications?.forEach(app => {
            val[app.term] = (app.requirements ?? {})
        })
        return val
    }, [applications]) 
    if (loading || !university) return <Wrapper style={{height: '80vh'}}><Loader/></Wrapper>
    return (
        <Wrapper>
            <HeaderTitle text={name} location={location} style={{ alignSelf: 'flex-start' }}/>
            <Header>
                <HeaderContent acronym={acronym} isMobile={isMobile}/>
            </Header>
            <Body>
                <Section style={styles.rowStyle(isMobile)}>
                    <FlexDirection.Column style={isMobile ? null : styles.desktopSectionWrapper}>
                        <AboutUs name={name} isMobile={isMobile} acronym={acronym} content={description} loc={{lat: universityAddress.longitude, long: universityAddress.latitude}}/>
                        {stats.length > 0 && <Stats isMobile={isMobile} stats={stats[0]}/>}
                    </FlexDirection.Column>
                    {!isMobile &&
                        <FlexDirection.Column style={{ width: '32%', flex: 0.4, height: '90%' }}>
                            <ImportantDates tabs={applicationTerms} content={applications} />
                            {contactDetails.length > 0  && <ContactInfo tabs={campuses} data={contactDetails} website={website}/>}
                        </FlexDirection.Column>}
                </Section>
                {rankings?.length > 0 &&
                    <Section>
                        {isMobile && <HeaderTitle text='Ranking' />}
                        <BubbleContainer style={isMobile ? styles.mobileSectionWrapper : styles.desktopSectionWrapper}>
                            <RankingCarousel rankings={rankings} limit={limit} isMobile={isMobile}/>
                        </BubbleContainer>
                    </Section>}
                {isMobile && <ImportantDates isMobile tabs={applicationTerms} content={applications} />}
                {Object.keys(requirements).length > 0
                    && <Section>
                        {isMobile && <HeaderTitle text='Requirements' />}
                       <Requirements requirements={requirements} isMobile={isMobile}/>
                      </Section>}
                <Section>
                    {isMobile && 
                        <FlexDirection.Row style={{ justifyContent: 'space-between' }}>
                            <HeaderTitle text='Faculties' />
                            {/* <TextInput placeholder="Search for Major" style={styles.mobileInputContainer} /> */}
                        </FlexDirection.Row>}
                    <Faculties onSelectItem={(ind) => setSelectedMajor(ind)} uniId={university.uniId} isMobile={isMobile} acronym={acronym}/>
                </Section>
                {isMobile && 
                    <Section>
                        <HeaderTitle text="Contact Info" />
                        {contactDetails.length > 0 && <ContactInfo isMobile tabs={campuses} data={contactDetails}  loc={{lat: universityAddress.longitude, long: universityAddress.latitude}} name={name} website={website}/>}
                    </Section>}
            </Body>
            <Modal id="major-details-modal" width={isMobile ? "100%" : "60%"}>
               <MajorDetails id={selectedMajorId} uniId={university.uniId}/>
            </Modal>
        </Wrapper>
    )
}

const HeaderContent = ({ acronym, isMobile }) => {
    const uniImageStore = useSelector((state: RootState) => state.localImageReducer.university)
    const images: string[] = useMemo(() => extractUniImages(acronym), [uniImageStore, acronym])
    return (
        <>
        <BubbleContainer style={{ padding: 0, flex: 0.6, position: 'relative' }}>
            <Image src={images?.[0] ?? defaultImage} />
        </BubbleContainer>
        <FlexDirection.Column style={{ flex: 0.5 }}>
            <FlexDirection.Row style={{ flex: 1 }}>
                <BubbleContainer style={{padding: 0}}>
                    <Image src={images?.[1] ?? defaultImage} style={styles.miniImage}/>
                </BubbleContainer>
                <BubbleContainer style={{ padding: 0, }}>
                    <Image src={images?.[2] ?? defaultImage} style={styles.miniImage}/>
                </BubbleContainer>
                {isMobile &&
                    <BubbleContainer style={{ padding: 0, }}>
                        <Image src={images?.[3] ?? defaultImage} style={styles.miniImage} />
                    </BubbleContainer>}
            </FlexDirection.Row>
            {!isMobile &&
                <FlexDirection.Row style={{ flex: 1 }}>
                    <BubbleContainer style={{ padding: 0, }}>
                        <Image src={images?.[3] ?? defaultImage} style={styles.miniImage} />
                    </BubbleContainer>
                    <BubbleContainer style={{ padding: 0, }}>
                        <Image src={images?.[4] ?? defaultImage} style={styles.miniImage} />
                    </BubbleContainer>
                </FlexDirection.Row>}
        </FlexDirection.Column>
    </>
    )
}

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${Space.large};
    background-color: ${Colors.white};
    align-self: center;
    width: 100%;
    margin-top: 70px;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        width: 90%;
        margin: auto;
        margin-top: 85px;
    }
`
const Header = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (min-width: ${CustomBreakPoints.tablet}) {
        flex-direction: row;
        flex: 1;
    }
`
const Image = styled.img`
    width: 100%;
    aspect-ratio: 1.3;
    object-fit: cover;
    transition: all .2s ease-in-out;
    &:hover{
        transform: scale(1.07);
    }
`
const Body = styled.div`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${Space.large};
    margin-bottom: ${Space.medium};
    flex: 1;
`
type Styles = {
    miniImage: React.CSSProperties;
    buttonStyle: React.CSSProperties;
    mobileSectionWrapper: React.CSSProperties;
    desktopSectionWrapper: React.CSSProperties;
    rowStyle: (isMobile?: boolean) =>  React.CSSProperties;
    mobileInputContainer: React.CSSProperties;
    desktopInputContainer: React.CSSProperties;
}
const styles: Styles = {
    miniImage: {
        objectFit: 'cover',
        height: '100%'
    },
    buttonStyle: {
        marginTop: Space.largest,
        backgroundColor: Colors.primary,
        color: Colors.secondary,
        alignSelf: 'center',
        width: '70%',
        fontWeight: 'bold',
        fontSize: Size.large_medium,
        borderRadius: '12px'
    },
    mobileSectionWrapper: {
        backgroundColor: Colors.white,
        margin: 0,
        padding: 0
    },
    desktopSectionWrapper: {
        width: '62%',
        flex: 0.7,
        marginRight: Space.medium
    },
    rowStyle: (isMobile) => (isMobile ? null : { flexDirection: 'row' }),
    mobileInputContainer: {
        height: '60%',
        padding: Space.medium,
        width: '40%' 
    },
    desktopInputContainer: {
        height: '60%',
        padding: Space.medium,
        width: '25%'
    },
}

export default UniveristyDetailsPage;