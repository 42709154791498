import Text from "components/Core/Text";
import TextInput from "components/Core/TextInput";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Range } from "react-daisyui";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import { ReactComponent as RightArrow } from 'assets/svgs/icons/right-arrow.svg';
import { ReactComponent as Check } from 'assets/svgs/icons/check.svg';
import { queryAllUniversities } from "API/webAPI";
import Modal from "components/Core/Modal";
import { createPortal } from "react-dom";
import { useMediaQuery } from "hooks/useMediaQuery";
import Button from "components/Core/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { applyFilters, initialState, reset, setDiff } from "redux/slices/filterSlice";
import Collapsible from "components/Core/Collapsible";
import classNames from "classnames";

const ScholarshipFilters = ({ refresh }) => {
    const filters = useSelector((state: RootState) => state.filterReducer)
    const classes1 = classNames("btn-primary", {"btn-disabled": !filters.diff})
    const classes2 = classNames("btn-accent", {"btn-disabled": !filters.filtersActive})
    const [filterByType, setFilterTypes] = useState(filters.Type)
    const [filterByAmount, setFilterAmount] = useState(filters.Amount)
    const [filterByUniversities, setFilterUniversity] = useState(filters.Universities)
    
    const dispatch = useDispatch()
    const updateTypeFilters = useCallback((key, value) => {
        const updatedValue = { ...filterByType, [key]: { ...filterByType[key], value: value} }
        setFilterTypes(updatedValue)
        dispatch(setDiff(true))
    }, [filterByType, setFilterTypes])

    const updateUniversityFilter = useCallback((payload) => {
        const updated = { ...filterByUniversities, store: payload, value: payload.map(item => item.id) }
        setFilterUniversity(updated)
        dispatch(setDiff(true))
    }, [filterByUniversities, setFilterUniversity])

    const updateCoverFilter = useCallback((payload) => {
        const updated = { ...filterByAmount, value: payload}
        setFilterAmount(updated)
        dispatch(setDiff(true))
        
    }, [filterByAmount, setFilterAmount])

    const _onResetFilters = useCallback(() => {
        dispatch(reset())
        setFilterTypes(initialState.Type)
        setFilterAmount(initialState.Amount)
        setFilterUniversity(initialState.Universities)
        refresh()
    }, [filters.filtersActive])

    const _onApplyFiltersHandler = useCallback(() => {
        dispatch(applyFilters({ Type: filterByType, Universities: filterByUniversities, Amount: filterByAmount }))
        refresh()
    }, [filterByAmount, filterByUniversities, filterByType, filters.diff])

    return (
        <Wrapper>
            {document.getElementById('items-listing')
                && createPortal(<UniversitySearchModal filteredUnis={filterByUniversities.store} updateUniversityFilter={(items) => updateUniversityFilter(items)}/>, document.getElementById('items-listing'))}
            <HeaderWrapper>
                <Text fontSize={Size.larger} fontWeight={700}>
                    Filters
                </Text>
                <FlexDirection.Row style={{ alignItems: 'center' }}>
                    <B className={classes1} htmlFor="filter-modal" onClick={_onApplyFiltersHandler}>
                        Apply Filters
                    </B>
                    <B className={classes2} htmlFor="filter-modal" onClick={_onResetFilters} style={{ marginLeft: Space.medium }}>
                        Reset All
                    </B>
                </FlexDirection.Row>
            </HeaderWrapper>
           
            <CotentWrapper className="divide-y">
                <Collapsible title="Type">
                    {Object.keys(filterByType).map(key => {
                            const isChecked = filterByType[key].value
                            return (
                                <Item>
                                    <Checkbox size="xs" checked={isChecked} onClick={() => updateTypeFilters(key, !isChecked)}/>
                                    <Text margin={{ left: Space.medium }}>{key}</Text>
                                </Item>
                            )
                        })}
                </Collapsible>
                <Collapsible title="Covers Up To">
                    <FlexDirection.Row style={{ alignItems: 'center' }}>
                        <InputWrapper>
                            <TextInput id="range-text" value={filterByAmount.value} style={{ width: '100%', height: '30px' }}
                                onInput={() => {
                                    const input = document.getElementById("range-text") as HTMLInputElement
                                    let value = input.value
                                    value = value.replace(/[^0-9]/g, '');
                                    if (value.length === 0) value = '0'
                                    else  {
                                        const n = parseFloat(value);
                                        value = n.toString()
                                    }
                                    updateCoverFilter(value)
                                }}/>
                        </InputWrapper>
                        <Range
                            onChange={() => {
                                const input = document.getElementById('range') as HTMLInputElement | null;
                                updateCoverFilter(input.value)
                            }}
                            value={filterByAmount.value} size="sm" id="range"/>
                    </FlexDirection.Row>
                </Collapsible>
                <label htmlFor="university-selector-modal" className="bg-base-200" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className="collapse-title text-xl font-medium">
                        <Text fontWeight={600} fontSize={Size.large_medium}>
                            Universities
                        </Text>
                    </div>
                    <RightArrow height={30} width={45} />
                </label>
            </CotentWrapper>
        </Wrapper>
       
    )
}

const UniversitySearchModal = ({ updateUniversityFilter, filteredUnis }) => {
    const [selectedUnis, setSelectedUnis] = useState(filteredUnis)
    const [displayedItems, setDisplayedItems] = useState<[{ id: string, name: string, acronym: string}]>()
    const [items, setItems] = useState<[{ id: string, name: string, acronym: string }]>()
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)

    const onItemSelect = useCallback((item) => {
        const found = selectedUnis.filter(uni => uni.id === item.id)
        if (found.length > 0) return
        const s = [...selectedUnis]
        s.push(item)
        setSelectedUnis(s)
    }, [selectedUnis])

    const onDeleteItem = useCallback((item) => {
        const updatedItems = selectedUnis.filter(uni => uni.id !== item.id)
        setSelectedUnis(updatedItems)
    }, [selectedUnis])

    const onModalClose = useCallback(() => {
        updateUniversityFilter(selectedUnis)
    }, [selectedUnis])

    const onTextChange = useCallback((input: string) => {
        const filteredItems = items?.filter(item => item.name.toLocaleLowerCase().startsWith(input.toLocaleLowerCase()))
        //@ts-ignore
        setDisplayedItems(filteredItems)
    }, [items])
    
    const onInit = useCallback(async () => {
        try {
            const data = await queryAllUniversities()
            setItems(data)
            setDisplayedItems(data)
        } catch(err) {
            console.log("FilterContent: Failed to getAutoCompleteSearch -> ", err)
        }
    }, [])

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        setSelectedUnis(filteredUnis)
    }, [filteredUnis])
    return (
        <Modal id="university-selector-modal" onClose={onModalClose} width={isMobile ? "100%" : "50%"}>
            <UniversitySelectionWrapper isMobile={isMobile}>
                <Text semibold fontSize={Size.large} margin={{ bottom: Space.small }}>
                    University Filter
                </Text>
                <Text fontSize={Size.medium} margin={{ bottom: Space.large }}>
                   Please select one or more universities in which you are interested in displaying their scholarships
                </Text>
                <FlexDirection.Row style={{ alignItems: 'center', marginBottom: Space.large }}>
                    <SearchWrapper>
                        <TextInput
                            id="filter-by-university-search"
                            placeholder="Search for University"
                            onInput={() => {
                                const input = document.getElementById('filter-by-university-search') as HTMLInputElement
                                onTextChange(input.value)
                            }}
                            />
                    </SearchWrapper>
                    <Button children="Clear All" color="accent" onClick={() => setSelectedUnis([])} style={{ marginLeft: Space.medium }}/>
                </FlexDirection.Row>
                <div className="divide-y">
                {displayedItems?.map(item => {
                    const isSelected = selectedUnis?.filter(i => i.id === item.id)?.length > 0
                    return (
                        <FlexDirection.Row onClick={() => isSelected ? onDeleteItem(item) : onItemSelect(item)}
                            style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <FlexDirection.Row style={{ alignItems: 'center' }}>
                                <div style={{ height: 25, width: 25, borderRadius: 12.5, backgroundColor: Colors.primary, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: Space.medium }}>
                                    <Text fontSize={Size.thin} color={Colors.white}>
                                        {item.acronym}
                                    </Text>
                                </div>
                                <Text fontSize={isMobile ? Size.medium : Size.large_medium} style={{ paddingTop: Space.large, paddingBottom: Space.large }}>
                                    {item.name}
                                </Text>
                            </FlexDirection.Row>
                            {isSelected && <Check height={20} width={20} style={{ marginRight: Space.largest }}/>}
                        </FlexDirection.Row>
                    )
                })}
                </div>
            </UniversitySelectionWrapper>
        </Modal>
    )
} 

const Wrapper = styled.div`
    padding-top: ${Space.large};
    border-radius: 8;
    overflow: scroll;
`
const HeaderWrapper = styled(FlexDirection.Row)`
    justify-content: space-between;
    align-items: center;
    margin-top: ${Space.large};
`
const CotentWrapper = styled.div`
  padding-top: ${Space.medium};
`
const Item = styled.div`
   display: flex;
   align-items: center;
   margin-top: ${Space.medium};
`
const InputWrapper = styled.div`
    width: 100px;
    margin-right: ${Space.medium};
`
const B = styled.label`
    border-radius: 10px;
    padding: ${Space.medium};
    padding-right: ${Space.large};
    padding-left: ${Space.large};
    color: white;
    font-size: ${Size.large_medium};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1em;
    height: 2.7rem;
    font-weight: 500;
    text-transform: capitalize;
`
const UniversitySelectionWrapper = styled.div<{ isMobile: boolean }>`
    min-height: 350px;
    margin-top: ${props => props.isMobile ? Space.largest : null};
`
const SearchWrapper = styled.div`
    flex: 1;
    display: flex;
`
export const FilterContent = { ScholarshipFilters };