import React from "react";
import { Space } from "utils/constants";
import classNames from "classnames";
import { Link } from "wouter";
import { logNavigateEvent } from "tracking/google";
interface Props {
    currentPage: number;
    totalPages: number;
    route: string;
    params?: string;
    pageSize: number
}
const Pagination: React.FC<Props> = ({ currentPage, totalPages, route, params, pageSize }) => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    return (
        <div className="btn-group" style={styles.buttonGroup}>
            {pages.map((p) => {
                return (
                    <PaginationItem key={p} size={pageSize} route={route} active={p === currentPage} page={p} params={params} />
                )
            })}
        </div>
    )
}

interface ItemProps {
    page: number,
    active?: boolean,
    route: string,
    params?: string,
    size: number
}
const PaginationItem: React.FC<ItemProps> = ({ page, active, route, params, size }) => {
    const classes = classNames("btn", { "btn-active": active })
    return (
        <Link className={classes} to={`${route}/${page}`} onClick={() => logNavigateEvent('', `${route}/${page}`, 'pagination')}>
            {page}
        </Link>
    )
}

const styles: {[name: string] : React.CSSProperties} = {
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: Space.medium,
        marginBottom: Space.medium,
        width: '100%'
    }
}
export default Pagination;