import React, { useEffect, useMemo, useState } from 'react';
import TextInput from './TextInput';
import { InputProps } from 'react-daisyui';
import classNames from 'classnames';

export type SearchProps= Partial<InputProps>
    & {
        rightcomponent?: JSX.Element;
        hideBorder?: boolean;
        onChangeText: (t: string) => void;
        searchQuery?: string;
        status?: "idle" | "loading";
        searchResults: any;
        onSelectItem: (item: any) => void;
        onReset: () => void;
        setQuery: (q: string) => void;
        instantSearch?: boolean;
        dropDownStyle?: React.CSSProperties;
        alignment?: 'left';
    }


const SearchInputV2: React.FC<SearchProps> = (props) => {
    const { onChangeText, status, id, searchQuery, searchResults, onReset, setQuery, instantSearch, dropDownStyle, alignment, ...customProps } = props;
    const [hidden, setHidden] = useState(false)
    useEffect(() => {
        if (!searchQuery || searchQuery === '') {
            onReset()
            return
        }
        if (instantSearch) {
            onChangeText(searchQuery)
            return
        }
        const timeout = setTimeout(() => {
            onChangeText(searchQuery)
        }, 1000)
        return () => {
            clearTimeout(timeout)
        }
    },[searchQuery])

    return (
        <>
        <TextInput 
            value={searchQuery}
            id={id}
            onBlur={() => {
                setTimeout(() => {
                    setHidden(true)
                    setQuery('')
                }, 150)
            }}
            onFocus={() => setHidden(false)}
            onInput={() => {
                const input = document.getElementById(id) as HTMLInputElement | null;
                setQuery(input.value)
            }}
            {...customProps} />
        <SearchResults
            onSelectItem={props.onSelectItem}
            results={searchResults}
            status={status}
            isHidden={hidden || searchQuery === ''}
            leftAligned={alignment === 'left'}
            style={dropDownStyle}/>
        </>
    )
}

interface Props {
    results: any;
    status: 'loading' | 'idle';
    isHidden: boolean;
    onSelectItem: (item: any) => void;
    leftAligned?: boolean;
    style: React.CSSProperties;
}
export const SearchResults: React.FC<Props> = ({ results, status, isHidden, onSelectItem, leftAligned, style }) => {
    const _onItemClickHandler = (key: string, item?: string) => onSelectItem({ id: key, name: item })
    const items = Object.keys(results);
    const classes = classNames("dropdown dropdown-open dropdown-bottom", { "dropdown-left": leftAligned })
    if (isHidden) return
    return (
        <div className={classes} style={{ display: 'flex', flexDirection: 'column' }}>
        <ul tabIndex={0}
            className="dropdown-content z[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            style={{ maxHeight: '300px', flexWrap: 'nowrap', overflow: 'scroll', ...style }}>
            {status === 'loading'
                ? <li><a>Loading</a></li>
                : items.length === 0
                    ? <li>
                        <a>
                            No results Found
                        </a>
                      </li>
                    : Object.keys(results).map(key => {
                        return (
                            <li style={{flex: 1}} key={key} onClick={() => _onItemClickHandler(key, results[key])}>
                                <a>
                                    {results[key]}
                                </a>
                            </li>
                        )
                    })
            }
        </ul>
        </div>
    )
}
SearchInputV2.defaultProps =  {
    id: 'input',
    status: 'idle',
}
export default SearchInputV2;