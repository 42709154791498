import { BubbleContainer, bubbleContainerStyle } from "components/StyledComponents/BubbleContainer";
import React, { useState } from "react";
import { Colors, Space } from "utils/constants";
import HeaderTitle from "./HeaderTitle";
import CustomTab from "components/Core/Tabs";
import { Requirement } from "utils/types";
import RowValuedLabel from "./RowValuedLabel";

interface Props {
    isMobile?: boolean;
    requirements: {[name: string]: Requirement[]}
}
const Requirements: React.FC<Props> = ({ isMobile, requirements }) => {
    const tabs = Object.keys(requirements)
    const [tabIndex, setTabIndex] = useState(tabs[0])
    const requirement = requirements[tabIndex]
    return (
        <BubbleContainer style={isMobile ? {marginTop: Space.large} : bubbleContainerStyle.desktop}>
            {!isMobile && <HeaderTitle text='Requirements' />}
            {/* {tabs.length > 1 &&
                <CustomTab 
                    tabs={tabs.slice(0,1)}
                    tabsContainerStyle={{ backgroundColor: Colors.secondary, marginBottom: Space.medium }}
                    onTabChange={({ label }) => setTabIndex(label)}/>} */}
            <div className="divide-y">
            {requirement?.map((req: Requirement, index) => {
                return <RowValuedLabel label={req.name} value={req.value ?? '-'} style={{ paddingTop: index > 0 ? Space.medium : null }}/>
                })}
            </div>
        </BubbleContainer>
    )
}

export default Requirements;