import React from 'react';
import { InputProps, Input, InputGroup } from 'react-daisyui';
import { Colors } from 'utils/constants';

export type TextInputProps <T> = Partial<T> & {rightcomponent?: JSX.Element, hideBorder?: boolean, bgColor?: Colors | string}

const TextInput: React.FC<TextInputProps<InputProps>> = (props) => {
    return (
        !props.rightcomponent
            ? <CustomInput {...props}/>
            : <InputGroup>
                <CustomInput {...props}/>
                {props.rightcomponent && props.rightcomponent}
              </InputGroup>
    )
}

const CustomInput: React.FC<TextInputProps<InputProps>> = (props) => {
    return <Input id='input' {...props} style={{ flex: '1 0 50%', borderWidth: props.hideBorder ? 0 : null, backgroundColor: props.bgColor, ...props.style }}/>
}

export default TextInput;