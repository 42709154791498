import errorSvg from "assets/svgs/illustrations/404.svg";
import Button from "components/Core/Button";
import Text from "components/Core/Text";
import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import styled from "styled-components";
import { CustomBreakPoints, Routes, Size, Space } from "utils/constants";
import { goTo } from "utils/helpers";

const Error404Page = () => {
  const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
  return (
    <Wrapper>
      <Svg src={errorSvg}/>
      <Text
        margin={{ bottom: Space.large, left: Space.medium, right: Space.medium }}
        fontSize={isMobile ? Size.large_medium : Size.larger}
        style={{ textAlign: 'center' }}>
        It seems like the dog ate the page you were looking for.
      </Text>
      <Button children="Go Back to Home Page" onClick={() => goTo(Routes.home, [], true)} />
    </Wrapper>
  );
};

export default Error404Page;

const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Svg = styled.img`
  display: flex;
  height: 60%;
  width: 60%;
  min-width: 250px;
  min-height: 250px;
  @media (max-width: ${CustomBreakPoints.tablet}){
    height: 40%;
  }
`;