import classNames from "classnames";
import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "hooks/useMediaQuery";
import { CustomBreakPoints } from "utils/constants";
import 'components/css/common.css'
interface ModalProps {
    isOpen?: boolean;
    children?: any;
    width?: string | number;
    overrideStyle?: React.CSSProperties;
    onClose?: any;
    id: string;
}
const Modal: React.FC<ModalProps> = ({ isOpen, children, width, overrideStyle, onClose, id }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.phone})`)
    const [isVisible, setVisibility] = useState(isOpen)
    const classes = classNames("modal", {"modal-open": isVisible, "modal-bottom": isMobile, "modal-middle": !isMobile})
    const toggleNavBar = (visibility) => {
        if (onClose && !visibility) onClose()
        document.getElementById('navbar')?.classList?.toggle('hide', visibility)
        setVisibility(visibility)
    }
    return (
        <>
        <input type="checkbox" checked={isVisible} id={id} className="modal-toggle" onChange={() => {
            toggleNavBar(!isVisible)
        }}/>
        <div className={classes}>
            <ModalBox className="modal-box relative" isMobile={isMobile} width={width} style={overrideStyle}>
                {!isMobile && <CloseLabel htmlFor={id} className="btn btn-sm btn-circle absolute" children='x' />}
                {isVisible ? children : null}
            </ModalBox>
            {isMobile &&  <CloseLabel htmlFor={id} className="btn btn-sm btn-circle absolute" children='x' isMobile/>}
        </div>
        </>
    )
}
const ModalBox = styled.div<{ isMobile: boolean, width: string | number }>`
    width: ${props => props.width};
    max-width: 100%;
    max-height: ${props => props.isMobile ? "85%" : "75%"};
    height: ${props => props.isMobile ? '85%' : null};
    overflow-x: hidden;
`
const CloseLabel = styled.label<{ isMobile?: boolean }>`
    right: 6px;
    top: ${props => props.isMobile ? '16%' : '6px'};
`
Modal.defaultProps = {
    width: '50%'
}
export default Modal;