import Text from "components/Core/Text";
import { skeletonState } from "components/StyledComponents/SkeletonAnimation";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useMemo } from "react";
import { Tooltip } from "react-daisyui";
import styled from "styled-components";
import { Colors, CustomBreakPoints, Size, Space } from "utils/constants";

interface Props {
    label?: string;
    value?: string;
    displayNumber?: boolean;
    additionalContent?: string;
    toolTipValue?: string;
    forceSmallText?: boolean;
    loading?: boolean;
}
const ValuedLabel: React.FC<Props> = ({ label, value, displayNumber, additionalContent, toolTipValue, forceSmallText, loading }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const textSize = useMemo(() => {
        if (forceSmallText ||isMobile) return Size.thin_medium
        return Size.large_medium
    }, [isMobile, forceSmallText])
    return (
        <LabelItemWrapper toolTip={toolTipValue} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexBasis: 0, flexGrow: 1}}>
            {additionalContent &&
                <Text color="grey" fontSize={textSize} bold children={additionalContent} style={{ textAlign: 'center', flexGrow: 1}}/>}
            <Text color={Colors.black} fontSize={textSize} semibold margin={{ bottom: Space.small }}>
                {!displayNumber ? label : `#${parseInt(label)}`}
            </Text>
            {loading ? <Skeleton /> : <Text color="grey" fontSize={Size.thin_medium} bold children={value} />}
        </LabelItemWrapper>
    )
}
const LabelItemWrapper = ({ toolTip, style, children }) => {
    return (!toolTip ? <div style={style}>{children}</div> : <Tooltip message={toolTip} style={style} children={children}/>)
} 

const Skeleton = styled.div`
    height: 14px;
    width: 40%;
    ${skeletonState}
`
export default ValuedLabel;