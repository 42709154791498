import styled from "styled-components";
import { Colors, CustomBreakPoints, Space } from "utils/constants";
import { skeletonState } from "./SkeletonAnimation";

export const BubbleContainer = styled.div<{ isLoading?: boolean}>`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 18px;
    overflow: hidden;
    background-color: ${Colors.blueish};
    box-sizing: border-box;
    padding: ${Space.large};
    margin: ${Space.small};
    -webkit-mask-image: -webkit-radial-gradient(circle, white, white);
    ${props => props.isLoading ? skeletonState : null}
    @media (max-width: ${CustomBreakPoints.tablet}) {
        border-radius: 12px;
    }
`
export const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${Space.large};
    margin-bottom: ${Space.medium};
    flex: 1;
`

type Style = {
    mobile: React.CSSProperties;
    desktop: React.CSSProperties;
}
export const bubbleContainerStyle: Style = {
    mobile: {
        backgroundColor: Colors.white,
        margin: 0,
        padding: 0
    },
    desktop: {
        width: '62%',
        flex: 0.7,
        marginRight: Space.medium
    },
}