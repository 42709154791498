import Text from "components/Core/Text";
import React from "react";
import { Space } from "./constants";

export class CustomMarkupParser {
    tags: { list: (content: any) => JSX.Element; b: (content: any) => JSX.Element; h: (content: any) => JSX.Element; };
    constructor() {
        this.tags = {
            'list': content => <li style={{ marginTop: Space.smallest, marginBottom: Space.smallest }}>{content}</li>,
            'b': content => <Text fontWeight={600} margin={{ bottom: Space.smallest }}>{content}</Text>,
            'h': content => <Text fontWeight={600} margin={{ bottom: Space.small, top: Space.small }}>{content}</Text>
        };
    }

    parse(inputText) {
        let outputElement = <></>;
        if (!inputText) return null
        const lines = inputText.split('\n');
        for (const line of lines) {
            const { tag, content } = this.extractTagContent(line);
            if (tag) {
                if (tag in this.tags) {
                    outputElement = <>{outputElement.props.children}{this.tags[tag](content)}</>
                }
            } else {
                outputElement = line
            }
        }
        return outputElement;
    }

    extractTagContent(line) {
        const matches = line.match(/<([^>]+)>([^<]+)<\/\1>/);
        if (matches) {
            const [, tag, content] = matches;
            return { tag, content };
        }
        return { tag: null, content: null };
    }
}