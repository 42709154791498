import React, { useCallback, useEffect, useState } from 'react';
import 'react-modern-drawer/dist/index.css'
import { ScholarshipListingResponse } from 'types/Scholarship';
import { getScholarshipList } from 'API/webAPI';
import ScholarshipDetails from 'components/Scholarship/ScholarshipDetails';
import Modal from 'components/Core/Modal';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { CustomBreakPoints, Routes } from 'utils/constants';
import ItemsListingCopy from './ItemsListingCopy';
import { Buffer } from "buffer";
import { goTo } from 'utils/helpers';

interface Props {
  page: string;
  size: string;
  id?: number;
  acronym: string;
  base64: string;
}
const ScholarshipsListingAdmin: React.FC<Props> = ({ page, size, id, acronym, base64 }) => {
    const [data, setData] = useState<ScholarshipListingResponse | undefined>();
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const [loading, setLoading] = useState(false)
    const [selectedId, setSelectedId] = useState(id)
    
    const fetchScholarships = useCallback(() => {
      window.scrollTo(0, 0)
      setLoading(true)
      const s = Buffer.from(base64, "base64").toString()
      if (s === "jusoorio"+acronym) {
          getScholarshipList(size, page, { universities: acronym })
              .then(res => {
                  setLoading(false)
                  setData(res)
              })
              .catch(err => {
                  setLoading(false)
                  console.log('err', err)
                  goTo(Routes.error, [], true)
              })
      } else {
        goTo(Routes.notfound, [],true)
        
      }
    }, [base64, acronym, size, page])
    useEffect(() => {
      fetchScholarships()
    }, [size, page])
  
    const scholarships = data?.results
    const pagination = { next: data?.next, prev: data?.prev, count: data?.count, params: '', current: parseInt(page), size: parseInt(size)}
    
    return (
      <>
        <ItemsListingCopy
            paginationInfo={pagination}
            acronym={acronym}
            base64={base64}
            loading={loading}
            items={scholarships}
            id={id}
            extensions={{ setSelectedId }}
            />
          <Modal id="scholarship-details-modal" width={isMobile ? "100%" : "60%"} isOpen={!!selectedId} onClose={() => setSelectedId(null)}>
            <ScholarshipDetails id={selectedId} />
          </Modal>
        </>
    )
}

export default ScholarshipsListingAdmin;
