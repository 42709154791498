import Text from "components/Core/Text";
import React, { useMemo } from "react";
import { CustomBreakPoints, Size, Space } from "utils/constants";
import styled from "styled-components";
import { capitalizeFirstLetter } from "utils/helpers";
import { useMediaQuery } from "hooks/useMediaQuery";
import { skeletonState } from "components/StyledComponents/SkeletonAnimation";

interface Props {
    label?: string;
    value?: string;
    isLoading?: boolean;
    style?: React.CSSProperties
}
const RowValuedLabel: React.FC<Props> = ({ label, value, isLoading, style }) => {
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const textSize = useMemo(() => {
        if (isMobile) return Size.thin_medium
        return Size.medium
    }, [isMobile])
    return (
        <Div style={style}>
            <Text bold fontSize={textSize} color='grey' children={capitalizeFirstLetter(label)}/>
            {isLoading ? <Skeleton/> 
                : <Text semibold fontSize={textSize} children={value?.length > 0 ? value : '-'} style={{ flex: 0.5, textAlign: 'end'}}/>}
        </Div>
    )
}

const Div = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: ${Space.medium};
`
const Skeleton = styled.div`
    width: 25%;
    height: 13px;
    ${skeletonState}
`
export default RowValuedLabel;