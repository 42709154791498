import { RefObject, useEffect, useMemo, useState } from 'react';

export const useOnScreen = (ref: RefObject<HTMLElement>) =>{

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => isIntersecting ? isIntersecting : setIntersecting(entry.isIntersecting)
    ), [ref])
  
    const disconnect = () => {
        observer.disconnect()
    }
    
    useEffect(() => {
      observer.observe(ref.current)
      return () => observer.disconnect()
    }, [])
  
    return {isIntersecting, disconnect}
  }