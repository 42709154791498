import { analytics } from "App";
import { logEvent } from "firebase/analytics";
import { ReactNode } from "react";

export const logOnButtonClick = (title: ReactNode) => {
    logEvent(analytics, 'button_click', {
        button_name: title,
    })
}

export const logErrorEvent = (reason: string, info?: {name?: string, endpoint?: string}) => {
    const msg = reason.toString ? reason.toString() : 'Generic Error'
    logEvent(analytics, 'error_event', {
        reason: msg,
        name: info?.name,
        endpoint: info?.endpoint,
    })
}

export const logNavigateEvent = (fromPage: string, toPage: string, by: string) => {
    logEvent(analytics, 'navigate', {
        fromPage,
        goingTo: toPage,
        by,
    })
}

export const logSuccessfulSubscribe = (from: string) => {
    logEvent(analytics, 'subscribed_event', {
       from
    })
}