import styled from "styled-components";
import React from "react";
import { Colors, Space, CustomBreakPoints, Size } from "utils/constants";
import Text from "./Text";
import { useMediaQuery } from "hooks/useMediaQuery";

const ContactNote = (props) => {
  const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
  return (
    <OuterDiv>
      <ImageDiv>
        <img src={props.imgSrc} alt={props.imgSrc.split("."[0])}></img>
      </ImageDiv>
      <TitleDiv>{props.textTitle}</TitleDiv>
      {/* for email change it to link to open the mail app */}
      <Text style={{ textAlign: 'center' }} fontSize={isMobile ? Size.thin_medium : Size.medium}>
        {props.textNote.includes("@") ? (
          <a href={`mailto:${props.textNote}`}>{props.textNote}</a>
        ) : (
          props.textNote
        )}
      </Text>
    </OuterDiv>
  );
};
export default ContactNote;
const OuterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: ${Space.medium};
  padding-left: ${Space.medium};
  height: 10em;
`;
const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 5vh;
  margin-top: ${Space.large};
  @media (max-width: ${CustomBreakPoints.tablet}) {
    height: 3vh;
  }
`;

const TitleDiv = styled.div`
  font-size: ${Size.large};
  font-weight: 600;
  text-align: center;
  margin-top: ${Space.large};
  @media (max-width: ${CustomBreakPoints.tablet}) {
    font-size: ${Size.large_medium};
  }
  @media (max-width: ${CustomBreakPoints.phone}) {
    font-size: ${Size.thin_medium};
    margin-left: ${Space.large};
    margin-right: ${Space.large};
    margin-bottom: ${Space.large};
  }
`;