import Text from 'components/Core/Text';
import React, { useCallback, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Colors, CustomBreakPoints, Routes, Size, Space, defaultImage } from 'utils/constants';
import { ReactComponent as Pin } from 'assets/svgs/icons/pin.svg';
import { ReactComponent as Medal } from 'assets/svgs/icons/medal.svg';
import { goTo, transformDeadlineToStatus } from 'utils/helpers';
import { RegistrationStatus } from 'utils/types';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { University } from 'types/University';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { skeletonState } from 'components/StyledComponents/SkeletonAnimation';
import { logNavigateEvent } from 'tracking/google';

const UniversityItem: React.FC<{university: University, index: number, isLoading?: boolean}> = (props) => {
    const { name, uniId, acronym, addresses, rankings } = props.university;
    const isLoading = props.isLoading
    const location = addresses?.length > 0 ? addresses[0].city : '-'
    const ranking = rankings?.length > 0 ? 'Ranked ' + parseInt(rankings[0].rank) + ` in ${rankings[0].region}` : '-'
    const uniImageStore = useSelector((state: RootState) => state.localImageReducer.university)
    const imageURL = useMemo(() => {
        if (!acronym) return ''
        const a = acronym.toLowerCase().replace(" ", "");
        return uniImageStore[a]?.thumbnail
    }, [uniImageStore, acronym])
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const _onClickHandler = useCallback(() => {
        const ac = acronym.toLocaleLowerCase()
        goTo(Routes.universityDetails, [ac])
        logNavigateEvent(Routes.university, Routes.universityDetails + `/${ac}`, 'university_card')
    }, [acronym])
    return (
        <Wrapper onClick={isLoading ? null : _onClickHandler}>
            {
                <>
                <Header isLoading={isLoading} >
                    {!props.isLoading && <Image src={imageURL} style={{ height: '100%', width: '100%', objectFit: 'cover' }} loading="lazy"/>}
                </Header>
                <Body>
                    <Text isLoading={isLoading} bold fontSize={isMobile ? Size.large_medium : Size.large} margin={{top: Space.small}}>
                        {name}
                    </Text>
                    {isLoading && <Text isLoading style={{ width: '50%' }}/>}
                    <div style={styles.infoWrapper}>
                        <div style={styles.leftStyle}>
                            <div style={styles.infoRow}>
                                <Pin height={15} width={15} />
                                <Text isLoading={isLoading} fontSize={Size.thin_medium} margin={{ left: Space.small }} fontWeight={700}>
                                    {location}
                                </Text>
                            </div>
                            <div style={styles.infoRow}>
                                <Medal height={15} width={15} />
                                <Text isLoading={isLoading} fontSize={Size.thin_medium} margin={{ left: Space.small }} fontWeight={700}>
                                    {ranking}
                                </Text>
                            </div>
                        </div>
                    </div>
                </Body>
                </>}
        </Wrapper>
    )
}


interface Props {
    registrationStartDate: Date;
    registrationEndDate: Date;
    isUnknown?: boolean
}
const RegistrationStatusUI: React.FC<Props> = ({ registrationStartDate, registrationEndDate, isUnknown }) => {
    const status = isUnknown ? RegistrationStatus.UNKNOWN : transformDeadlineToStatus(registrationStartDate, registrationEndDate);
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    let textColor = "rgb(0, 200, 0)";
    let bgColor = "rgba(141, 235, 141, 0.424)";
    let text = "Unknown"
    switch (status) {
        case RegistrationStatus.CLOSED:
            textColor = "#373636";
            bgColor = "rgba(94, 94, 94, 0.424)";
            text = "Closed"
            break;
        case RegistrationStatus.OPENING:
            textColor = "rgb(2, 47, 207)";
            bgColor = "rgba(38, 74, 205, 0.424)";
            text = "Opening Soon"
            break;
        case RegistrationStatus.CLOSING:
            textColor = "rgb(200,0,0)";
            bgColor = "rgba(235, 141, 141, 0.424)";
            text = "Closing Soon"
            break;
        case RegistrationStatus.OPEN:
            textColor = "rgb(0, 200, 0)";
            bgColor = "rgba(141, 235, 141, 0.424)";
            text = "Open"
            break;
        case RegistrationStatus.NOT_OPEN:
            textColor = "rgb(227, 179, 4)";
            bgColor = "rgba(223, 198, 71, 0.424)";
            text = "Not Open Yet"
            break;
        default:
            textColor = "rgb(100, 0, 200)";
            bgColor = "rgba(133, 71, 195, 0.424)";
            text = "Not Announced"
    }
    return (
    <div style={{...styles.statusWrapper, ...{backgroundColor: bgColor}}}>
        <Text color={textColor} fontSize={isMobile ? '0.65rem' : '0.7rem'} bold>
            {text}
        </Text>
    </div>)
}

const Wrapper = styled.div<{ isLoading?: boolean }>`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${Colors.grey};
    width: 90%;
    aspect-ratio: 1.2;
    margin: 0.6em;
    position: relative;
    box-sizing: border-box;
    transition: all .2s ease-in-out;
    overflow: hidden;
    ${props => props.isLoading ? skeletonState : null}
    @media (min-width: ${CustomBreakPoints.tablet}) {
        width: 290px;
        aspect-ratio: 0.8;
        
    }
    &:hover{
        transform: scale(1.07);
    }
`
const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`
const Header = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex: 0.7;
    flex-basis: 0;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    ${props => props.isLoading ? skeletonState : null}
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0.3;
    flex-grow: 0.4;
    width: 100%;
    padding: ${Space.medium};
    box-sizing: border-box;
`

type Styles = {
    infoRow: React.CSSProperties;
    leftStyle: React.CSSProperties;
    statusWrapper: React.CSSProperties;
    infoWrapper: React.CSSProperties;
}
const styles: Styles = {
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: Space.medium,
        boxSizing: 'border-box'
    },
    leftStyle: {
        marginTop: Space.medium
    },
    statusWrapper: {
        borderRadius: 22,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 12,
        paddingLeft: 12
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}
export default UniversityItem;