import Text from 'components/Core/Text';
import { UnderDash } from 'components/StyledComponents/UnderDash';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useMemo } from 'react'
import { CustomBreakPoints, Size, Space } from 'utils/constants';
import { ReactComponent as PinIcon } from "assets/svgs/icons/pin.svg";

interface HeaderProps {
    text: string;
    textSize?: Size | string;
    style?: React.CSSProperties;
    hideUnderLine?: boolean;
    location?: string;
    subHeading?: boolean;
}
const HeaderTitle: React.FC<HeaderProps> = ({ text, style, textSize, hideUnderLine, location, subHeading }) => {
    const isIpad = useMediaQuery(`(min-width: ${CustomBreakPoints.tablet}) and (max-width: ${CustomBreakPoints.desktop}) and (orientation : portrait)`)
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.tablet})`)
    const titleSize = useMemo(() => {
        if (subHeading) {
            if (isMobile) return Size.medium
            if (isIpad) return Size.large_medium
            return Size.large
        }
        if (isMobile) return Size.large_medium
        if (isIpad) return Size.large
        return Size.larger
    }, [isMobile, isIpad, subHeading])
    return (
        <div style={{ ...styles.titleWrapper, ...style }}>
            <Text bold fontSize={textSize ?? titleSize} style={{ lineHeight: '25px' }} margin={{ bottom: Space.small }}>
                {text}
            </Text>
            {!hideUnderLine && <UnderDash />}
            {location &&
                <div style={styles.locationWrapper}>
                    <PinIcon height="15px" width="15px" />
                    <Text semibold color="grey" fontSize={isMobile ? Size.thin_medium : '0.9rem'} margin={{ left: Space.medium }}>
                        {location}
                    </Text>
                </div>}
        </div>
    )
}

type Styles = {
    titleWrapper: React.CSSProperties;
    locationWrapper: React.CSSProperties;
}
const styles: Styles = {
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: Space.large
    },
    locationWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: Space.medium
    },
}
export default HeaderTitle;