import { createSlice } from "@reduxjs/toolkit"

interface Interface {
    university: any,
    scholarship: any,
}

const initialState: Interface = {
    scholarship: {},
    university: {}
}

const localImageSlice = createSlice({
    name: 'localImage',
    initialState,
    reducers: {
        setImage: (state, { payload }) => {
            const { type, folder, name, url} = payload;
            const prevFolder = state[type][folder] ?? {}
            state[type] = { ...state[type], [folder]: {...prevFolder, [name]: url}}
        }
    }
})

export const { setImage } = localImageSlice.actions
export default localImageSlice.reducer