import { BubbleContainer, Section } from "components/StyledComponents/BubbleContainer";
import React, { useCallback, useEffect, useState } from "react";
import HeaderTitle from "./HeaderTitle";
import CustomTab from "components/Core/Tabs";
import { Colors, Space } from "utils/constants";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import { Application } from "utils/types";
import ValuedLabel from "./ValuedLabel";
import { Divider } from "components/StyledComponents/Divider";
import { formatNumber } from "utils/helpers";
import Button from "components/Core/Button";
import { ReactComponent as LinkIcon } from "assets/svgs/icons/link-icon.svg";

interface Props {
    tabs: string[];
    content: Application[];
    isMobile?: boolean;
}
const ImportantDates: React.FC<Props> = ({ tabs, content, isMobile }) => {
    const [tabIndex, setTabIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const { startDate, deadline, currency, fees, link } = content[tabIndex] ?? {};
    const handleTabChange = ({ ind }) => {
        setLoading(true)
        setTabIndex(ind)
    }
    const renderBody = useCallback(() => {
        return (
            <>
            <HeaderTitle text='Application' />
            {tabs.length > 1 && <CustomTab onTabChange={handleTabChange} tabs={tabs} tabsContainerStyle={{ backgroundColor: Colors.white }} />}
            <FlexDirection.Row style={{ alignItems: 'center', marginTop: Space.medium }}>
                <ValuedLabel label='Open' value={startDate ?? '-'} loading={loading}/>
                <Divider />
                <ValuedLabel label='Deadline' value={deadline ?? '-'} loading={loading}/>
                <Divider />
                <ValuedLabel label='Fee' value={fees ?`${currency} ${ formatNumber(parseInt(fees))}` : '-'} loading={loading}/>
            </FlexDirection.Row>
            {link && <Button color="accent" children="Apply"
                style={{ marginTop: Space.largest, width: isMobile ? '40%' : '90%', alignSelf: 'center'  }}
                onClick={() => window.open(link)}
                endIcon={<LinkIcon height={'20px'} width={'20px'} style={{ marginLeft: Space.medium }}/>}/>}
            </>
        )
    }, [tabIndex, tabs, content, loading])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false)
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    }, [tabIndex])

    if (isMobile) {
        return (
            <Section>
                {renderBody()}
            </Section>
        )
    }
    return (
        <BubbleContainer style={{ flex: 0.8 }}>
            {renderBody()}
        </BubbleContainer>
    )
}

export default ImportantDates;