import React, { useState } from "react";
import { Swiper } from 'swiper/react'
import 'swiper/css';
import "swiper/css/grid";
import "swiper/css/pagination";
import 'components/css/common.css';
import { CustomBreakPoints, Space } from "utils/constants";
import { useMediaQuery } from "hooks/useMediaQuery";
import { FreeMode, Mousewheel, Grid, Scrollbar } from "swiper";

interface Props {
    isVertical?: boolean;
    children: React.ReactNode;
    slidesPerView?: number;
    spaceBetween?: number;
    style?: React.CSSProperties;
    showPagination?: boolean;
    isFreeMode?: boolean;
    enableGrid?: boolean;
    enableScrollIndicator?: boolean;
    numOfRows?: number;
}
const ScrollView: React.FC<Props> = ({ children, isVertical, slidesPerView, spaceBetween, style, showPagination, isFreeMode, enableGrid, numOfRows, enableScrollIndicator }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const arr = Array.from(Array(React.Children.count(children)).keys())
    const isMobile = useMediaQuery(`(max-width: ${CustomBreakPoints.phone})`)
    return (
        <Swiper
            style={{ width: '100%', overflow: 'visible', ...style }}
            slidesPerView={slidesPerView ?? 'auto'}
            direction={ isVertical ? 'vertical' : 'horizontal' }
            spaceBetween={spaceBetween}
            onActiveIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
            modules={[FreeMode, Mousewheel, Grid, Scrollbar]}
            scrollbar={{hide: !enableScrollIndicator, enabled: enableScrollIndicator}}
            grid={enableGrid ? { rows: numOfRows, fill: 'row' } : null}
            mousewheel={{ forceToAxis: true }}
            freeMode={isFreeMode}
        >
            {children}
            {showPagination && arr.length > 1 &&
                <div style={styles.pagination(isMobile)}>
                    {arr.map((_, ind) => <div style={activeIndex === ind ? styles.activeBullet : styles.bullet} />)}
                </div>}
        </Swiper>
    )
}

type Style = {
    activeBullet: React.CSSProperties,
    bullet: React.CSSProperties,
    pagination: (isMobile?: boolean) => React.CSSProperties
}
const styles: Style = {
    activeBullet: {
        height: '6px',
        width: '14px',
        borderRadius: '4px',
        backgroundColor: 'black'
    },
    bullet: {
        height: '6px',
        width: '6px',
        borderRadius: '3px',
        marginRight: Space.medium,
        marginLeft: Space.medium,
        backgroundColor: '#aaa'
    },
    pagination: (isMobile) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: isMobile ? Space.large : Space.medium,
        marginBottom: Space.small
    })
} 
export default ScrollView;