import { configureStore } from "@reduxjs/toolkit";
import localImageSlice from "./slices/localImageStore";
import userSlice from "./slices/userSlice";
import filterSlice from "./slices/filterSlice";

const reducer = {
    localImageReducer: localImageSlice,
    userReducer: userSlice,
    filterReducer: filterSlice,
}
export const store = configureStore({
    reducer,
})

export type RootState = ReturnType<typeof store.getState>
