import { BubbleContainer, bubbleContainerStyle } from "components/StyledComponents/BubbleContainer";
import { FlexDirection } from "components/StyledComponents/FlexDirection";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import HeaderTitle from "./HeaderTitle";
import { getUniversityFaculties } from "API/webAPI";
import ScrollView from "components/Core/ScrollView";
import { PartialMajor } from "utils/types";
import { SwiperSlide } from "swiper/react";
import MajorItem from "components/Major/MajorItem";
import { Colors, Space, defaultImage } from "utils/constants";
import Text from "components/Core/Text";
import styled from "styled-components";
import TextInput from "components/Core/TextInput";

interface Props {
    isMobile?: boolean;
    uniId: number;
    acronym: string;
    onSelectItem: (ind: number) => void 
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'fetch-start':
            return { loading: true, error: null, faculties: {} }
        case 'fetch-done':
            return { loading: false, error: null, faculties: action.data, filteredFaculties: action.displayed }
        case 'fetch-failed':
            return { loading: false, error: action.error, faculties: {} }
        case 'set-filtered-faculties':
            return { ...state, filteredFaculties: action.data }
    }
}

const Faculties: React.FC<Props> = ({ isMobile, uniId, onSelectItem, acronym }) => {
    const [selectedFaculty, setSelectedFaculty] = useState<string | undefined>()
    const [searchQuery, setSearchQuery] = useState<undefined | string>()
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        error: null,
        faculties: {}
      });
    const { faculties, filteredFaculties } = state;
    const allMajors = useMemo(() => {
        if (!faculties) return []
        const m = []
        Object.values(faculties).forEach(major => {
            Object.values(major).forEach(s => m.push(s))
        })
        return m
    }, [faculties])

    const onInit = useCallback(async () => {
        try {
            dispatch({ type: 'fetch-start' })
            const results = await getUniversityFaculties(uniId)
            const keys = Object.keys(results)
            setSelectedFaculty(keys[0])
            dispatch({ type: 'fetch-done', data: results, displayed: results[keys[0]] })
        } catch(err) {
            dispatch({ type: 'fetch-failed', error: err })
        }
    }, [uniId])

    useEffect(() => {
        onInit()
    }, [])

    const renderFacultyBubbles = useCallback(() => {
        return (
            Object.keys(faculties).map(faculty => {
                const isSelected = selectedFaculty === faculty
                if (!isMobile)
                    return (
                        <Div isSelected={isSelected} onClick={() => setSelectedFaculty(faculty)}>
                            <Text color={isSelected ? Colors.white : null}>
                                {faculty}
                            </Text>
                        </Div>
                    )
                return (
                    <Slide isSelected={isSelected} onClick={() => setSelectedFaculty(faculty)}>
                        <Text color={isSelected ? Colors.white : null}>
                            {faculty}
                        </Text>
                    </Slide>
                )
            })
        )
    }, [faculties, selectedFaculty, isMobile])

    useEffect(() => {
        let unmount = false
        if (!unmount && faculties?.[selectedFaculty]) dispatch({ type: 'set-filtered-faculties', data: faculties[selectedFaculty] })
        return () => {
            unmount = true
        }
    }, [selectedFaculty])

    useEffect(() => {
        if (!searchQuery) return
        if (searchQuery === '' && faculties?.[selectedFaculty]) {
            dispatch({ type: 'set-filtered-faculties', data: faculties[selectedFaculty] })
            return
        }
        const timeout = setTimeout(() => {
            const majors = allMajors.filter(m => m.name.toLocaleLowerCase().startsWith(searchQuery.toLocaleLowerCase()))
            dispatch({ type: 'set-filtered-faculties', data: majors })
            setSelectedFaculty(majors?.[0]?.faculty ?? '')
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    },[searchQuery])

    if (!faculties || Object.keys(faculties).length === 0) return
    return (
        <BubbleContainer style={isMobile ? bubbleContainerStyle.mobile : { width: '100%' }}>
            {!isMobile && 
                <FlexDirection.Row style={{ justifyContent: 'space-between' }}>
                    <HeaderTitle text='Faculties' />
                    <div style={{ height: '60%', width: '30%', alignItems: 'flex-end', display: 'flex'}} >
                        <TextInput placeholder={`Search for Major`} value={searchQuery}  onInput={() => {
                const input = document.getElementById('input') as HTMLInputElement | null;
                setSearchQuery(input.value)
            }}/>
                    </div>
                    
                </FlexDirection.Row>}
            {isMobile &&  <div style={{ width: '95%', alignItems: 'center', display: 'flex', marginBottom: Space.medium}} >
            <TextInput placeholder={`Search for Major`} value={searchQuery}  onInput={() => {
                const input = document.getElementById('input') as HTMLInputElement | null;
                setSearchQuery(input.value)
            }}/>
                    </div>}
            {isMobile ?
                <ScrollView isFreeMode>
                    {renderFacultyBubbles()}
                </ScrollView>
                :
                <FlexDirection.Row style={{ flexWrap: 'wrap' }}>
                    {renderFacultyBubbles()}
                </FlexDirection.Row>
            }
            <ScrollView
              slidesPerView={isMobile ? 2.3 : 4.8}
              spaceBetween={15}
              isFreeMode
              enableGrid
              numOfRows={3}
              enableScrollIndicator
              style={{ marginTop: Space.largest, paddingBottom: isMobile ? 20 : 0 }}>
                {filteredFaculties?.map((major: PartialMajor) => {
                    return (
                        <SwiperSlide key={major.id}>
                            <MajorItem acronym={acronym} imageURL={defaultImage} major={major} onSelectMajor={onSelectItem}/>
                        </SwiperSlide>
                    )
                })}
            </ScrollView>
        </BubbleContainer>
    )
}

const Div = styled.div<{ isSelected: boolean }>`
    border-width: 1px;
    border-color:${Colors.primary};
    border-radius: 18px;
    padding: ${Space.medium};
    padding-left: ${Space.large};
    padding-right: ${Space.large};
    background-color: ${props => props.isSelected ? Colors.primary : null};
    margin-right: ${Space.medium};
    margin-top: ${Space.medium};
`
const Slide = styled(SwiperSlide)<{ isSelected: boolean }>`
    border-width: 1px;
    border-color:${Colors.primary};
    border-radius: 18px;
    padding: ${Space.medium};
    padding-left: ${Space.large};
    padding-right: ${Space.large};
    background-color: ${props => props.isSelected ? Colors.primary : null};
    margin-right: ${Space.medium};
    margin-top: ${Space.medium};
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
`
export default Faculties;