import { createSlice } from "@reduxjs/toolkit"

interface Interface {
    corporateUser: { token: null },
}

const initialState: Interface = {
    corporateUser: null
}

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setToken: (state, { payload }) => {
            state.corporateUser = payload
        }
    }
})

export const { setToken } = userSlice.actions
export default userSlice.reducer