import React, { useCallback, useReducer } from "react";
import { getAutoCompleteSearch } from "API/webAPI";
import { ListingItems } from "utils/types";
import SearchInputV2 from "./Core/SearchInputV2";
import { SearchProps } from "./Core/SearchInputV2";

type Props = Partial<SearchProps> & {
    type: ListingItems;
    overrideClasses?: string;
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'fetch-start':
            return { ...state, status: "loading", error: null, searchResults: {} }
        case 'fetch-done':
            return { ...state, status: "idle", error: null, searchResults: action.data }
        case 'fetch-failed':
            return { ...state, status: "idle", error: action.error, searchResults: {} }
        case 'set-query':
            return { ...state, query: action.query }
        case 'clear':
            return { status: "idle", query: "", searchResults: {}, error: null }
    }
}
const SearchEngine: React.FC<Props> = ({ type, overrideClasses, ...rest }) => {
    const [state, dispatch] = useReducer(reducer, {
        status: "idle",
        error: null,
        searchResults: {},
        query: null
    });
    const { status, searchResults, query } = state;

    const _onChangeHandler = useCallback(async (text: string) => {
        try {
            dispatch({ type: "fetch-start" })
            const results = await getAutoCompleteSearch(type, text)
            dispatch({ type: "fetch-done", data: results})
        } catch (err) {
            console.log(err)
            dispatch({ type: "fetch-failed", error: err})
        }
       
    }, [type])

    const _handleOnItemClick = useCallback((item) => {
        const path = type === ListingItems.University ? '/university-details' : '/scholarship-details'
        dispatch({ type: "clear" })
        window.open(process.env.REACT_APP_WEB_URL + `${path}/${item.id}`)
    }, [type])

    return (
        <SearchInputV2
            placeholder="Search"
            className={overrideClasses ?? "w-1/2"}
            status={status}
            searchQuery={query}
            searchResults={searchResults}
            onChangeText={_onChangeHandler}
            onSelectItem={_handleOnItemClick}
            setQuery={(q) => dispatch({ type: "set-query", query: q })}
            {...rest}
            onReset={() => dispatch({ type: "clear" })} />
    )
}
SearchEngine.defaultProps = {
    type: ListingItems.University
}
export default SearchEngine;