import styled from "styled-components";
import { CustomBreakPoints, Space } from "utils/constants";

export const Divider = styled.div`
    height: 50px;
    width: 2px;
    background-color: #ddd;
    margin-left: ${Space.medium};
    margin-right: ${Space.medium};
    align-self: center;
    @media (max-width: ${CustomBreakPoints.tablet}) {
        height: 45px;
    }
`