import styled from "styled-components";

const Row = styled.div`
    display: flex;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
`

export const FlexDirection = { Row,  Column }